<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-row justify="end">
              <v-col cols="12" sm="8">
                <v-row v-if="selectedContainers.length > 0">
                  <v-btn
                    color="blue"
                    class="mx-1"
                    small
                    outlined
                    rounded
                    style="text-transform: none"
                    @click="changeDestinationModal=true"
                  >
                    <v-icon small left>mode_of_travel</v-icon>
                    Change Destination
                  </v-btn>
                  <v-btn
                    color="orange"
                    class="mx-1"
                    small
                    outlined
                    rounded
                    style="text-transform: none"
                  >
                    <v-icon small left>warning</v-icon>
                    Short Shipment
                  </v-btn>
                </v-row>
              </v-col>
              <v-col cols="12" sm="4" class="my-0 py-0">
                <v-chip>
                  <v-text-field
                    placeholder="Search"
                    class="mb-1"
                    hide-details
                    rounded
                    clearable
                    dense
                    style="width: 300px"
                    v-model="search"
                  ></v-text-field>
                </v-chip>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" class="my-0 py-0">
            <v-data-table
              id="containers-table"
              :items="bookings"
              disable-pagination
              hide-default-footer
              height="55vh"
              fixed-header
              :headers="displayHeaders"
              :search="search"
            >
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center" align="center">
                  <v-btn
                    icon
                    @click="expandBooking(item)"
                    small
                    v-if="item.containerPallets.length > 0"
                  >
                    <v-icon
                      small
                      v-if="expanded.map((x) => x.id).includes(item.id)"
                      >expand_less</v-icon
                    >
                    <v-icon small v-else>expand_more</v-icon>
                  </v-btn>
                  <v-tooltip top v-if="item.containerNo && canViewContainerTelemetry">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="trackContainerMetrics(item)"
                        style="text-transform: none"
                        icon
                        ><v-icon small>timeline</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">Track Metrics</span>
                  </v-tooltip>

                  <v-tooltip top v-if="item.containerNo && item.eventUrl">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="trackContainer(item)"
                        style="text-transform: none"
                        icon
                        ><v-icon small>location_searching</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">Track Container</span>
                  </v-tooltip>

                  <v-tooltip top v-if="shipment.breakBulkShipment">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="editDeckNumberModal(item)"
                        style="text-transform: none"
                        icon
                        ><v-icon small>edit</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">Edit Deck Number</span>
                  </v-tooltip>

                  <v-tooltip top v-if="item.containerPallets.length > 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        id="view-content"
                        v-bind="attrs"
                        v-on="on"
                        @click="openContainer(item)"
                        style="text-transform: none"
                        icon
                        ><v-icon small>launch</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">View Container Contents</span>
                  </v-tooltip>
                  <v-tooltip top v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        id="view-content"
                        v-bind="attrs"
                        v-on="on"
                        @click="viewContainerModal(item)"
                        style="text-transform: none"
                        icon
                        ><v-icon small>launch</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">View Container</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        @click="invoiceDetails(item)"
                        style="text-transform: none"
                        icon
                        :color="item.invoiceNo ? 'success' : 'orange'"
                        small
                        ><v-icon small>monetization_on</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px" v-if="!item.invoiceNo"
                      >No invoice details</span
                    >
                    <span style="font-size: 12px" v-else
                      >Invoice: {{ item.invoiceNo }}
                      {{ item.invoiceDate }}</span
                    >
                  </v-tooltip>
                </v-row>
              </template>

              <template v-slot:[`item.stockSealNo`]="{ item }">
                <v-btn
                  text
                  @click="editStockSealNo(item)"
                  small
                  >{{item.stockSealNo}}</v-btn>
              </template>
              <template v-slot:[`item.nettWeight`]="{ item }">
                <span>{{ containerNettWeight(item) }}</span>
              </template>

              <template v-slot:[`item.grossWeight`]="{ item }">
                <span>{{ containerGrossWeight(item) }}</span>
              </template>

              <template v-slot:[`item.additional`]="{ item }">
                <v-tooltip
                  color="transparent"
                  top
                  v-if="containerMetaCheck(item).length > 0"
                >
                  <template v-slot:activator="{ on }">
                    <v-chip
                      v-on="on"
                      outlined
                      :color="
                        containerMetaSuccess(item) ==
                        containerMetaCheck(item).length
                          ? 'success'
                          : containerMetaSuccess(item) > 0
                          ? 'orange'
                          : 'red'
                      "
                      >{{
                        containerMetaSuccess(item) +
                        "/" +
                        containerMetaCheck(item).length
                      }}</v-chip
                    >
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-for="check in containerMetaCheck(item)"
                      :key="check.id"
                    >
                      <v-list-item-action>
                        <v-icon
                          color="success"
                          v-if="verify(check.key, item.containerMetadata)"
                          >check_circle_outline</v-icon
                        >
                        <v-icon v-else color="red">cancel</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ check.key }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-tooltip>
              </template>
              <template v-slot:[`item.booking.state`]="{ item }">
                <v-chip
                  small
                  outlined
                  :color="getIconColor(item.booking.state)"
                >
                  {{ item.booking.state }}
                </v-chip>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-tooltip top v-if="item.status">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ item.status }}</span>
                  </template>
                  <span>Last Update: {{ formatDate(item.statusDate) }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.statusLocode`]="{ item }">
                <v-tooltip top v-if="item.statusLocode">
                  <template v-slot:activator="{ on }">
                    <v-chip v-on="on" pill small>
                      <v-avatar size="32" left>
                        <v-img
                          contain
                          :src="`https://cdn.loglive.io/flags/4x3/${item.statusLocode
                            .substring(0, 2)
                            .toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                      {{ item.statusLocode }}
                    </v-chip>
                  </template>
                  <span
                    >{{ item.statusLocation }}
                    {{ formatDate(item.statusDate) }}</span
                  >
                </v-tooltip>
              </template>

              <template v-slot:[`item.noPallets`]="{ item }">
                {{ calculateUniquePallets(item.containerPallets) }}
              </template>
              <template v-slot:[`item.noCartons`]="{ item }">
                {{ calculateCartons(item.containerPallets) }}
              </template>

              <!-- <template v-slot:expanded-item="{ headers, item }">
                <td class="px-1" :colspan="headers.length">
                  <v-expand-transition>
                    <v-card flat style="border-radius: 20px" class="my-1">
                      <v-card-text class="my-0">
                        <v-row>
                          <v-col
                            cols="12"
                            sm="4"
                            class="mt-0 pt-0"
                            v-if="
                              item.containerPallets &&
                              item.containerPallets.length > 0
                            "
                          >
                            <v-list dense v-if="shipmentProducts && shipmentProducts.length > 0">
                              <v-list
                                dense
                                v-for="(
                                  product, index
                                ) in containerProductSummary(item)"
                                :key="product.productId"
                              >
                                <v-subheader style="font-size: 14px"
                                  ><v-chip class="mr-2" small>{{
                                    index + 1
                                  }}</v-chip>
                                  {{ product.product.name }}</v-subheader
                                >
                                <v-list-item class="mt-0 pt-0">
                                  <v-list-item-content>
                                    <div
                                      v-for="(
                                        packType, typeIndex
                                      ) in product.packTypes"
                                      :key="typeIndex"
                                    >
                                      <v-list-item-subtitle>
                                        <v-icon
                                          color="secondary"
                                          x-small
                                          class="mr-2"
                                          >category</v-icon
                                        >
                                        {{ packType.cartons }}
                                        {{ packType.type }}
                                      </v-list-item-subtitle>
                                      <v-list-item-subtitle>
                                        <v-icon
                                          color="secondary"
                                          x-small
                                          class="mr-2"
                                          >scale</v-icon
                                        >
                                        Nett: {{ packType.nettWeight }}kg -
                                        Gross: {{ packType.grossWeight }}kg
                                      </v-list-item-subtitle>
                                      <v-divider class="mt-2"></v-divider>
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                  v-for="variety in containerVarietySummary(
                                    item,
                                    product
                                  )"
                                  :key="variety.varietyId"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title style="font-size: 12px">
                                      {{ variety.variety.name }}
                                    </v-list-item-title>
                                    <div
                                      v-for="(
                                        packType, typeIndex
                                      ) in variety.packTypes"
                                      :key="typeIndex"
                                    >
                                      <v-list-item-subtitle>
                                        <v-icon
                                          color="grey"
                                          x-small
                                          class="mr-2"
                                          >category</v-icon
                                        >
                                        {{ packType.cartons }}
                                        {{ packType.type }}
                                      </v-list-item-subtitle>
                                      <v-list-item-subtitle>
                                        <v-icon
                                          color="grey"
                                          x-small
                                          class="mr-2"
                                          >scale</v-icon
                                        >
                                        Nett: {{ packType.nettWeight }}kg -
                                        Gross: {{ packType.grossWeight }}kg
                                      </v-list-item-subtitle>
                                    </div>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-chip
                                      small
                                      outlined
                                      :color="
                                        getIconColor(variety.varietyPercentage)
                                      "
                                    >
                                      {{ variety.varietyPercentage }}%
                                    </v-chip>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list>
                            </v-list>
                            <v-list dense v-else>
                              <v-list-item>
                                <span style="color: grey">No products listed</span>
                              </v-list-item>
                            </v-list>
                          </v-col>
                          <v-col cols="12" sm="8" :key="milestoneKey">
                            <ul class="events">
                              <li
                                v-for="event in item.containerMilestones"
                                :key="event.id"
                                style="list-style-type: none"
                                class="my-3"
                              >
                                <v-avatar
                                  class="mb-1 mr-2"
                                  size="10"
                                  :color="
                                    event.isActual ? 'success' : 'secondary'
                                  "
                                ></v-avatar>
                                <strong style="font-size: 14px">{{
                                  formatDateTime(event.date)
                                }}</strong>
                                <v-chip
                                  outlined
                                  style="border: none"
                                  small
                                  v-if="event.location"
                                >
                                  <v-avatar size="20" left>
                                    <v-img
                                      contain
                                      :src="`https://cdn.loglive.io/flags/4x3/${event.location
                                        .substring(0, 2)
                                        .toLowerCase()}.svg`"
                                    ></v-img>
                                  </v-avatar>
                                  {{ event.location }}
                                </v-chip>
                                <span class="ml-2" style="font-size: 14px">{{
                                  event.description
                                }}</span>
                                <span v-if="event.vesselName" class="ml-2"
                                  >- {{ event.vesselName }}
                                  {{ event.voyageNo }}</span
                                >
                              </li>
                            </ul>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-expand-transition>
                </td>
              </template> -->
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="trackerModal"
      persistent
      width="600px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card v-if="trackerModal">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Container Tracking: {{ container.containerNo }}
            <span v-if="container.ctoNo"> - {{ container.ctoNo }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(trackerModal = false), (events = [])">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <DCSAContainerTracking
            v-if="container.trackType == 'DCSA'"
            :events="events"
          />
          <ContainerMilestone v-else :events="events" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="tempModal"
      width="600px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>
          Manage Telemetry Device
          <v-spacer></v-spacer>
          <v-btn text @click="saveTempMonitor" color="primary">Save</v-btn>
          <v-btn text @click="tempModal = false">X</v-btn>
        </v-card-title>
        <v-card-text v-if="container">
          <v-text-field
            label="Serial No"
            v-model="container.tempSerialNo"
            outlined
            dense
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog
      v-model="deckModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card v-if="container">
        <v-card-title>
          Edit Deck Number
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="saveDeckNumber"
            color="secondary"
            icon
            :loading="savingDeckNo"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn text @click="deckModal = false">X</v-btn>
        </v-card-title>
        <v-card-subtitle>
          {{ container.ctoNo }}
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            label="Deck No."
            v-model="container.containerNo"
            outlined
            dense
            clearable
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="invoiceModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>
          Invoice <v-spacer></v-spacer>
          <v-btn text @click="(invoiceModal = false), (invoiceItem = {})"
            >X</v-btn
          >
        </v-card-title>
        <v-card-subtitle>
          {{ invoiceItem.containerNo }} - {{ invoiceItem.ctoNo }}
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            label="Invoice No."
            outlined
            dense
            clearable
            v-model="invoiceItem.invoiceNo"
          >
          </v-text-field>
          <v-text-field
            label="Invoice Date"
            outlined
            dense
            clearable
            v-model="invoiceItem.invoiceDate"
          >
          </v-text-field>
          <v-row justify="center" class="mt-2">
            <v-btn
              color="primary"
              :loading="savingInvoice"
              text
              @click="saveInvoice"
              >Save</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="containerModal"
      width="95vw"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card v-if="container">
        <v-toolbar flat>
          {{ container.ctoNo }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="secondary"
            @click="saveContainer"
            :loading="savingContainer"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn text @click="(containerModal = false), (container = {})"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4">
              <v-list dense>
                <v-text-field
                  outlined
                  dense
                  label="Container No."
                  v-model="container.containerNo"
                  hide-details
                  class="my-2 py-1"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Seal No."
                  v-model="container.sealNo"
                  hide-details
                  class="my-2 py-1"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Customer Ref"
                  v-model="container.customerContainerRef"
                  hide-details
                  class="my-2 py-1"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Gross Weight"
                  v-model="container.grossWeight"
                  type="number"
                  step="0.01"
                  suffix="KG"
                  hide-details
                  class="my-2 py-1"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Nett Weight"
                  v-model="container.nettWeight"
                  type="number"
                  step="0.01"
                  suffix="KG"
                  hide-details
                  class="my-2 py-1"
                ></v-text-field>
              </v-list>
            </v-col>
            <v-col cols="12" sm="4">
              <v-list dense subheader v-if="container.containerProducts">
                <v-divider></v-divider>
                <v-subheader style="font-size: 16px"
                  >Cargo/Products
                  <v-btn icon color="primary" @click="addContainerProduct">
                    <v-icon>add_circle_outline</v-icon></v-btn
                  >
                  <!-- <v-spacer></v-spacer> -->
                </v-subheader>
                <v-divider></v-divider>

                <v-list-item
                  v-if="
                    !container.containerProducts ||
                    (container.containerProducts &&
                      container.containerProducts.length == 0)
                  "
                >
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No products listed</span>
                  </v-list-item-content></v-list-item
                >
                <v-list-item
                  v-for="(cargo, index) in container.containerProducts"
                  :key="index"
                  @click="editContainerProduct(cargo)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-if="cargo.product">
                      {{ cargo.product.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">
                      {{ cargo.hsCode }}
                      <span v-if="cargo.pallets">
                        - {{ cargo.pallets }} PALLETS</span
                      >
                      <span v-if="cargo.quantity">
                        - {{ cargo.quantity }} {{ cargo.quantityType }}</span
                      >
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-if="
                        cargo.industrial || cargo.organic || cargo.hazardous
                      "
                      class="text-wrap"
                    >
                      <v-chip
                        small
                        color="blue-grey"
                        class="mx-1"
                        v-if="cargo.industrial"
                        ><v-icon small left>factory</v-icon> Industrial
                        Use</v-chip
                      >
                      <v-chip
                        small
                        color="green"
                        class="mx-1"
                        v-if="cargo.industrial"
                        ><v-icon small left>spa</v-icon> Organic</v-chip
                      >
                      <v-chip
                        small
                        color="green"
                        class="mx-1"
                        v-if="cargo.hazardous"
                        ><v-icon color="orange" left>warning</v-icon>
                        Hazardous</v-chip
                      >
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-if="
                        cargo.grossWeight || cargo.nettWeight || cargo.volume
                      "
                      class="text-wrap"
                    >
                      NETT {{ cargo.nettWeight }} KG - GROSS
                      {{ cargo.grossWeight }} KG - {{ cargo.volume }} CBM
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="4">
              <v-list dense>
                <v-divider></v-divider>
                <v-subheader style="font-size: 16px" :key="dataSourceKey">
                  <v-icon color="secondary" class="mr-2">bar_chart</v-icon>
                  Data Source(s)
                  <v-spacer></v-spacer>
                  <v-btn
                    :loading="savingDataSource"
                    @click="updateDataSource()"
                    color="blue"
                    icon
                  >
                    <v-icon>save</v-icon>
                  </v-btn>
                </v-subheader>
                <v-divider></v-divider>
                <v-progress-linear
                  v-if="loadingDataSource"
                  color="primary"
                  indeterminate
                ></v-progress-linear>
                <v-list-item
                  v-for="source in availableSources"
                  :key="source.id"
                  style="border-bottom: 0.5px solid grey"
                >
                  <v-list-item-content
                    @click="viewSource(source)"
                    style="cursor: pointer"
                  >
                    <v-list-item-title>
                      {{ source.type }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="source.locationCode">
                      <v-icon class="mr-2" color="grey" small
                        >location_on</v-icon
                      >
                      {{ source.locationCode }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="source.transactionTime">
                      <v-icon class="mr-2" color="grey" small>schedule</v-icon>
                      {{ source.transactionTime }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="text-wrap">
                      <v-icon class="mr-2" color="grey" small
                        >description</v-icon
                      >{{ source.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-switch
                      color="success"
                      v-model="source.active"
                      @change="checkDataSourceChanges()"
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  v-if="!loadingDataSource && availableSources.length == 0"
                >
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 12px"
                      >No data sources found</span
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Data Source Modal -->
    <v-dialog v-model="sourceModal" fullscreen persistent>
      <v-card v-if="sourceModal">
        <v-card-title>
          Manage Data Source
          <v-spacer></v-spacer>
          <v-btn text @click="(sourceModal = false), (selectedContainer = null)"
            >X</v-btn
          >
        </v-card-title>
        <v-card-subtitle>
          {{ sourceItem.name }}
          <br />
          <v-icon class="mr-2" small color="grey">label</v-icon>
          {{ sourceItem.type }}
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <!-- <v-col cols="12">
            </v-col> -->
            <v-col cols="12" v-if="sourceItem.sourceType == 'stockFile'">
              <v-data-table
                :items="sourceItem.contents"
                :headers="importHeaders"
                height="82vh"
                fixed-header
                dense
                disable-pagination
                hide-default-footer
              >
            </v-data-table>
            </v-col>
            <v-col cols="12" v-else>
              <v-row>
                <v-col cols="12" sm="2">
                  <v-list dense>
                    <v-subheader style="font-size: 16px"
                      >Containers</v-subheader
                    >
                    <v-divider></v-divider>
                    <v-list-item
                      v-for="container in sourceItem.stockFileContainers"
                      :key="container.id"
                      @click="selectedContainer = container"
                      :style="{
                        'border-left':
                          selectedContainer &&
                          selectedContainer.id == container.id
                            ? '3px solid var(--v-primary-base)'
                            : '',
                      }"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ container.containerNo }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" sm="10">
                  <v-data-table
                    v-if="selectedContainer"
                    :items="selectedContainer.contents"
                    :headers="importHeaders"
                    height="82vh"
                    fixed-header
                    dense
                    disable-pagination
                    hide-default-footer
                  >
          
                </v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model='sealStockDialog' width="20vw" persistent>
      <v-card>
        <v-card-title>
          Edit Stock Seal No
          <v-spacer></v-spacer>
          <v-btn icon @click="updateStockSealNo" color="primary"><v-icon>save</v-icon></v-btn>
          <v-btn text @click="sealStockDialog = false">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="Stock Seal No"
            v-model="selectedContainer.stockSealNo"
            outlined
            dense
          ></v-text-field>
        </v-card-text>
        
      </v-card>
    </v-dialog> 
    <v-dialog
      v-model="containerProductModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card v-if="containerProduct">
        <Products
          @close="(containerProductModal = false), (containerProduct = {})"
          :item="containerProduct"
          @deleted="deleteSelectedContainerProduct"
          @chosenProduct="updateSelectedContainerProduct"
        />
      </v-card>
    </v-dialog>

    <v-dialog v-model="changeDestinationModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
    <v-card></v-card>
    </v-dialog>

    <ContainerTrackingDialog ref="containerTrackingDialog" :shipmentId="shipment.shipmentReference" :intergrationCode="CONTAINER_TRACKING_INTEGRATION_CODES.HL" />
  </div>
</template>
<script>
import axios from "axios";
import Products from "../Bookings/Dialogs/ProductsDialog.vue";
// import SensorInfo from "../Telemetry/SensorInfo.vue";
import dateFormat from "dateformat";
import ContainerMilestone from "../ContainerTracking/ContainerMilestone.vue";

import DCSAContainerTracking from "../ContainerTracking/DCSA.vue";
// import ContainerView from "../Telemetry/ContainerSummary/ContainerView.vue"

import ContainerTrackingDialog from '@/components/Integrations/ContainerTracking/ContainerTrackingDialog.vue';
import { CONTAINER_TRACKING_INTEGRATION_CODES, HL_SHIPMENTLINE_ID } from "@/utils/constants";


export default {
  props: ["shipment", "bookings", "documents", "type", "shipmentProducts"],
  components: {
    ContainerMilestone,
    DCSAContainerTracking,
    Products,
    ContainerTrackingDialog
  },
  data: () => ({
    activeSources: [],
    availableSources: [],
    changeDestinationModal: false,
    dataSourceChange: false,
    dataSourceKey: 4000,
    auditLines: [2, 6, 13],
    chartKey: 0,
    container: {},
    containerModal: false,
    containerProduct: null,
    containerProductModal: false,
    containerKey: 0,
    deckModal: false,
    events: [],
    expanded: [],
    loadingMilestones: false,

    displayHeaders: [],
    headers: [
      {
        text: "Action",
        align: "center",
        width: "200px",
        value: "action",
        sortable: false,
      },
      {
        text: "Additional",
        value: "additional",
        align: "center",
      },
      {
        text: "Container No",
        value: "containerNo",
        align: "center",
      },
      // {
      //   text: "Status",
      //   align: "center",
      //   value: "booking.state",
      // },
      // {
      //   text: "Status",
      //   value: "status",
      //   align: "center",
      // },
      // {
      //   text: "Last Position",
      //   value: "statusLocode",
      //   align: "center",
      // },
      {
        text: "Client Ref",
        value: "customerContainerRef",
        align: "center",
      },

      // {
      //   text: "Booking No",
      //   align: "center",
      //   value: "booking.shipperReferenceNumbers",
      // },
      {
        text: "Carrier Ref",
        align: "center",
        value: "booking.carrierReferenceNumber",
      },

      {
        text: "CTO No",
        value: "ctoNo",
        align: "center",
      },
      {
        text: "Seal No",
        value: "sealNo",
        align: "center",
      },
      {
        text: "Stock Seal No",
        value: "stockSealNo",
        align: "center",
      },

      // {
      //   text: "Customer Ref.",
      //   value: "customerContainerRef",
      //   align: "center",
      // },

      {
        text: "Nett(KG)",
        value: "nettWeight",
        align: "center",
      },
      {
        text: "Gross(KG)",
        value: "grossWeight",
        align: "center",
      },
      {
        text: "Pallets",
        value: "noPallets",
        align: "center",
      },
      {
        text: "Cartons",
        value: "noCartons",
        align: "center",
      },
      {
        text: "Telemetry Device",
        value: "tempSerialNo",
        align: "center",
      },
      {
        text:'Transp.',
        value:'transporterName',
        align:'center'
      },

    ],
    loadingDataSource: false,
    milestoneKey: 2000,
    invoiceItem: {},
    invoiceModal: false,
    savingContainer: false,
    savingDataSource: false,
    savingDeckNo: false,
    savingInvoice: false,
    search: null,
    selectedContainers: [],
    sensorItem: {},
    sensorModal: false,
    sourceItem: {},
    sourceModal: false,
    selectedContainer: {},
    trackerModal: false,
    tempModal: false,
    titanKey: 0,
    selectedStockSealNo: null,
    sealStockDialog: false,
    sealLoader:false,
    CONTAINER_TRACKING_INTEGRATION_CODES
  }),
  watch: {
    "item.id": {
      immediate: true,
      handler() {
        if (this.shipment.breakBulkShipment) {
          let findIndex = this.headers.findIndex(
            (item) => item.value == "containerNo"
          );
          this.displayHeaders = this.headers;
          this.displayHeaders[findIndex].text = "Deck No.";
        } else {
          this.displayHeaders = this.headers;
        }
      },
    },
  },

  computed: {
    canViewContainerTelemetry() {
      const isSystemAdmin = [1, 2].includes(this.$store.state.user.tagId);
      return (isSystemAdmin && this.shipment.shippingLineId === HL_SHIPMENTLINE_ID);
    },
  },

  methods: {
    async updateStockSealNo(){
      //updating seal 
      this.sealLoader = true
      console.log('this.selectedStockSealNo',this.selectedContainer)
      let result = await this.$API.updateContainer({
        id: this.selectedContainer.id,
        stockSealNo: this.selectedContainer.stockSealNo
      })
      this.sealLoader = false
      this.sealStockDialog = false
    },
    editStockSealNo(item){
      this.selectedContainer = item
      this.sealStockDialog = true
    },
    addContainerProduct() {
      this.containerProduct = {
        shipmentId: this.shipment.id,
      };
      this.containerProductModal = true;
    },
    calculateUniquePallets(pallets) {
      return [...new Set(pallets.map((item) => item.sscc))].length;
    },
    calculateCartons(pallets) {
      let result = pallets.map((item) => parseInt(item.noCartons));
      return result && result.length > 0 ? result.reduce((a, b) => a + b) : 0;
    },
    calculateActualGrossWeight(pallets) {
      return pallets
        .map((item) => (parseFloat(item.grossWeight) * 100) / 100)
        .reduce((a, b) => a + b, 0);
    },
    calculateActualNettWeight(pallets) {
      return pallets.map((item) => item.nettWeight).reduce((a, b) => a + b, 0);
    },
    containerMetaCheck(item) {
      let types = ["Titan Ready", "Phytclean Verified"];
      let find = item.containerMetadata.filter((x) => types.includes(x.key));
      return find;
    },
    containerMetaSuccess(item) {
      let types = ["Titan Ready", "Phytclean Verified"];
      let find = item.containerMetadata.filter(
        (x) => types.includes(x.key) && x.value == "Passed"
      );
      return find.length;
    },
    containerProductSummary(container) {
      if (container.containerPallets && container.containerPallets.length > 0) {
        let uniqueProducts = [
          ...new Set(
            container.containerPallets.map((x) => x.productId).filter(Boolean)
          ),
        ];
        let result = [];
        let total = container.containerPallets
          .map((x) => parseInt(x.noCartons))
          .reduce((a, b) => a + b, 0);
        for (let i = 0; i < uniqueProducts.length; i++) {
          let filteredPallets = container.containerPallets.filter(
            (x) => x.productId && x.productId == uniqueProducts[i]
          );
          let sumCartons = filteredPallets
            .map((x) => parseInt(x.noCartons))
            .reduce((a, b) => a + b, 0);
          let varieties = [
            ...new Set(
              filteredPallets
                .filter((x) => x.varietyProductId)
                .map((y) => y.varietyProductId)
            ),
          ];
          let product = this.shipmentProducts.find(
            (x) => x.productId == uniqueProducts[i]
          );
          let obj = {
            productId: uniqueProducts[i],
            product: product ? product.product : null,
            cartons: sumCartons,
            productPercentage: ((sumCartons / total) * 100).toFixed(0),
            nettWeight: filteredPallets
              .map((x) => Math.round(parseFloat(x.nettWeight) * 100) / 100)
              .reduce((a, b) => a + b, 0)
              .toFixed(2),
            grossWeight: filteredPallets
              .map((x) => Math.round(parseFloat(x.grossWeight) * 100) / 100)
              .reduce((a, b) => a + b, 0)
              .toFixed(2),
            packTypes: [],
          };
          let packTypes = [...new Set(filteredPallets.map((x) => x.packType))];
          for (let type of packTypes) {
            let packObj = {
              type: type == "CARTON" ? "Carton(s)" : "Bin(s)",
              nettWeight: filteredPallets
                .filter((x) => x.packType == type)
                .map((x) => Math.round(parseFloat(x.nettWeight) * 100) / 100)
                .reduce((a, b) => a + b, 0)
                .toFixed(2),
              grossWeight: filteredPallets
                .filter((x) => x.packType == type)
                .map((x) => Math.round(parseFloat(x.grossWeight) * 100) / 100)
                .reduce((a, b) => a + b, 0)
                .toFixed(2),
              cartons: filteredPallets
                .filter((x) => x.packType == type)
                .map((x) => parseInt(x.noCartons))
                .reduce((a, b) => a + b, 0),
            };
            obj.packTypes.push(packObj);
          }

          result.push(obj);
          // let findVariety = this.shipmentProducts.filter(x => x.productId == uniqueProducts[i] && varieties.includes(x.varietyProductId))
          // for (let j = 0; j < findVariety.length; j++) {
          //   let filteredVariety = filteredPallets.filter(x => x.varietyProductId && x.varietyProductId == findVariety[j].varietyProductId)
          //   let sumVariety = filteredVariety.map(x => parseInt(x.noCartons)).reduce((a, b) => a + b, 0)

          // }
        }
        return result;
      } else {
        let uniqueProducts = [
          ...new Set(
            container.containerProducts.map((x) => x.productId).filter(Boolean)
          ),
        ];
        let result = [];
        let total = container.containerProducts
          .map((x) => parseInt(x.cartons))
          .reduce((a, b) => a + b, 0);
        for (let i = 0; i < uniqueProducts.length; i++) {
          let filteredPallets = container.containerProducts.filter(
            (x) => x.productId && x.productId == uniqueProducts[i]
          );
          let sumCartons = filteredPallets
            .map((x) => parseInt(x.cartons))
            .reduce((a, b) => a + b, 0);
          let varieties = [
            ...new Set(
              filteredPallets
                .filter((x) => x.varietyProductId)
                .map((y) => y.varietyProductId)
            ),
          ];
          let product = filteredPallets[0].product;
          for (let j = 0; j < varieties.length; j++) {
            let findVariety = filteredPallets.find(
              (x) =>
                x.productId == uniqueProducts[i] &&
                x.varietyProductId == varieties[j]
            );
            let filteredVariety = filteredPallets.filter(
              (x) =>
                x.varietyProductId &&
                x.varietyProductId == findVariety[j].varietyProductId
            );
            let sumVariety = filteredVariety
              .map((x) => parseInt(x.cartons))
              .reduce((a, b) => a + b, 0);
            let obj = {
              productId: uniqueProducts[i],
              product: product,
              cartons: sumCartons,
              productPercentage: ((sumCartons / total) * 100).toFixed(0),
              variety: findVariety[j].varietyProduct,
              varietyPercentage: ((sumVariety / sumCartons) * 100).toFixed(0),
              varietyCartons: sumVariety,
            };
            result.push(obj);
          }
        }
        return result;
      }
    },
    containerVarietySummary(container, product) {
      if (container.containerPallets && container.containerPallets.length > 0) {
        let result = [];
        let total = container.containerPallets
          .filter((x) => x.productId == product.productId)
          .map((x) => parseInt(x.noCartons))
          .reduce((a, b) => a + b, 0);
        let filteredPallets = container.containerPallets.filter(
          (x) => x.productId && x.productId == product.productId
        );
        let sumCartons = filteredPallets
          .map((x) => parseInt(x.noCartons))
          .reduce((a, b) => a + b, 0);
        let varieties = [
          ...new Set(
            filteredPallets
              .filter((x) => x.varietyProductId)
              .map((y) => y.varietyProductId)
          ),
        ];
        // let product = this.shipmentProducts.find(x => x.productId == product.productId)
        let findVariety = this.shipmentProducts.filter(
          (x) =>
            x.productId == product.productId &&
            varieties.includes(x.varietyProductId)
        );
        for (let j = 0; j < findVariety.length; j++) {
          let filteredVariety = filteredPallets.filter(
            (x) =>
              x.varietyProductId &&
              x.varietyProductId == findVariety[j].varietyProductId
          );
          let sumVariety = filteredVariety
            .map((x) => parseInt(x.noCartons))
            .reduce((a, b) => a + b, 0);
          let obj = {
            productId: product.productId,
            product: product.product,
            cartons: sumCartons,
            productPercentage: ((sumCartons / total) * 100).toFixed(0),
            variety: findVariety[j].varietyProduct,
            varietyId: findVariety[j].varietyProductId,
            varietyPercentage: ((sumVariety / sumCartons) * 100).toFixed(0),
            varietyCartons: sumVariety,
            nettWeight: filteredVariety
              .map((x) => Math.round(parseFloat(x.nettWeight) * 100) / 100)
              .reduce((a, b) => a + b, 0)
              .toFixed(2),
            grossWeight: filteredVariety
              .map((x) => Math.round(parseFloat(x.grossWeight) * 100) / 100)
              .reduce((a, b) => a + b, 0)
              .toFixed(2),
            packTypes: [],
          };
          let packTypes = [...new Set(filteredVariety.map((x) => x.packType))];
          for (let type of packTypes) {
            let packObj = {
              type: type == "CARTON" ? "Carton(s)" : "Bin(s)",
              nettWeight: filteredVariety
                .filter((x) => x.packType == type)
                .map((x) => Math.round(parseFloat(x.nettWeight) * 100) / 100)
                .reduce((a, b) => a + b, 0)
                .toFixed(2),
              grossWeight: filteredVariety
                .filter((x) => x.packType == type)
                .map((x) => Math.round(parseFloat(x.grossWeight) * 100) / 100)
                .reduce((a, b) => a + b, 0)
                .toFixed(2),
              cartons: filteredVariety
                .filter((x) => x.packType == type)
                .map((x) => parseInt(x.noCartons))
                .reduce((a, b) => a + b, 0),
            };
            obj.packTypes.push(packObj);
          }
          result.push(obj);
        }
        return result;
      } else {
        // let uniqueProducts = [...new Set(container.containerProducts.filter(x=>x.productId == product.id).map(x => x.productId).filter(Boolean))]
        let result = [];
        let total = container.containerProducts
          .map((x) => parseInt(x.cartons))
          .reduce((a, b) => a + b, 0);
        let filteredPallets = container.containerProducts.filter(
          (x) => x.productId && x.productId == product.id
        );
        let sumCartons = filteredPallets
          .map((x) => parseInt(x.cartons))
          .reduce((a, b) => a + b, 0);
        let varieties = [
          ...new Set(
            filteredPallets
              .filter((x) => x.varietyProductId)
              .map((y) => y.varietyProductId)
          ),
        ];
        for (let j = 0; j < varieties.length; j++) {
          let findVariety = filteredPallets.find(
            (x) =>
              x.productId == product.id && x.varietyProductId == varieties[j]
          );
          let filteredVariety = filteredPallets.filter(
            (x) =>
              x.varietyProductId &&
              x.varietyProductId == findVariety[j].varietyProductId
          );
          let sumVariety = filteredVariety
            .map((x) => parseInt(x.cartons))
            .reduce((a, b) => a + b, 0);
          let obj = {
            productId: product.id,
            product: product,
            cartons: sumCartons,
            productPercentage: ((sumCartons / total) * 100).toFixed(0),
            variety: findVariety[j].varietyProduct,
            varietyId: findVariety[j].varietyProductId,
            varietyPercentage: ((sumVariety / sumCartons) * 100).toFixed(0),
            varietyCartons: sumVariety,
            packTypes: [],
          };
          let packTypes = [...new Set(filteredVariety.map((x) => x.packType))];
          for (let type of packTypes) {
            let packObj = {
              type: type == "CARTON" ? "Carton(s)" : "Bin(s)",
              nettWeight: filteredVariety
                .filter((x) => x.packType == type)
                .map((x) => Math.round(parseFloat(x.nettWeight) * 100) / 100)
                .reduce((a, b) => a + b, 0)
                .toFixed(2),
              grossWeight: filteredVariety
                .filter((x) => x.packType == type)
                .map((x) => Math.round(parseFloat(x.grossWeight) * 100) / 100)
                .reduce((a, b) => a + b, 0)
                .toFixed(2),
              cartons: filteredVariety
                .filter((x) => x.packType == type)
                .map((x) => parseInt(x.cartons))
                .reduce((a, b) => a + b, 0),
            };
            obj.packTypes.push(packObj);
          }
          result.push(obj);
        }
        return result;
      }
    },
    containerDocs() {
      return [];
    },
    consignmentNotes(pallets) {
      let auditPallets = pallets.filter(
        (x, index) =>
          x.inspectionManifestNo &&
          this.auditLines.includes(index + 1) &&
          !x.consignmentNoteId
      );
      let result = [
        ...new Set(auditPallets.map((x) => x.inspectionManifestNo)),
      ];
      return result;
    },
    containerGrossWeight(container) {
      // return container.containerPallets.map((item) => (Math.round(parseFloat(item.grossWeight) * 100) / 100)).reduce((a, b) => a + b, 0).toFixed(2);
      if (container.containerPallets.length > 0) {
        return container.containerPallets
          .filter((x) => x.grossWeight)
          .map((item) => Math.round(parseFloat(item.grossWeight) * 100) / 100)
          .reduce((a, b) => a + b, 0)
          .toFixed(2);
      } else {
        return container.containerProducts
          .filter((x) => x.grossWeight)
          .map((item) => Math.round(parseFloat(item.grossWeight) * 100) / 100)
          .reduce((a, b) => a + b, 0)
          .toFixed(2);
      }
    },
    containerNettWeight(container) {
      if (container.containerPallets.length > 0) {
        return container.containerPallets
          .filter((x) => x.nettWeight)
          .map((item) => parseFloat(item.nettWeight))
          .reduce((a, b) => a + b, 0)
          .toFixed(2)
      } else {
        return container.containerProducts
          .filter((x) => x.nettWeight)
          .map((item) => Math.round(parseFloat(item.nettWeight)))
          .reduce((a, b) => a + b, 0)
          .toFixed(2);
      }
    },
    checkDataSourceChanges() {
      this.dataSourceChange = false;
      for (let i = 0; i < this.availableSources.length; i++) {
        let find = this.activeSources.findIndex(
          (x) => x.stockFileId == this.availableSources[i].id
        );
        if (this.availableSources[i].active && find == -1) {
          this.dataSourceChange = true;
          break;
        } else if (!this.availableSources[i].active && find > -1) {
          this.dataSourceChange = true;
          break;
        }
      }
      this.dataSourceKey++;
    },
    editDeckNumberModal(item) {
      this.container = item;
      this.deckModal = true;
    },
    editTempSerial(item) {
      this.container = item;
      this.tempModal = true;
    },
    editContainerProduct(product) {
      this.containerProduct = product;
      this.containerProduct.shipmentId = this.shipment.id;
      this.containerProductModal = true;
    },
    async expandBooking(item) {
      let findIndex = this.expanded.findIndex((x) => x.id == item.id);
      if (findIndex == -1) {
        this.getContainerMilestones(item);

        this.expanded = [item];
        // if (item.eventUrl) {
        //   this.container = item;
        //   this.container.trackType = item.eventUrl.includes('OceanInsight') ? 'OceanInsight' : 'DCSA'
        //   let data = await axios.get(item.eventUrl)
        //   this.events = data.data
        // }
      } else {
        this.expanded.splice(findIndex, 1);
      }
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy HH:MM");
      }
      return result;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy HH:MM");
      }
      return result;
    },
    formatEventDate(date) {
      let year = date.substring(0, 4);
      let month = parseInt(date.substring(4, 6)) - 1;
      let day = date.substring(6, 8);
      let hour = date.substring(8, 10);
      let minute = date.substring(10, 12);
      let setDate = new Date(year, month, day, hour, minute);
      return dateFormat(setDate, "dd mmm, yyyy HH:MM");
    },
    formatNumber(number) {
      return number.toLocaleString(undefined, { minimumFractionDigits: 0 });
    },
    async getAvailableSources() {
      this.loadingDataSource = true;
      let result = await this.$API.getAvailableStockSources(this.container.id);
      this.activeSources = await this.$API.getActiveStockSources(
        this.container.id
      );
      for (let i = 0; i < result.length; i++) {
        let find = this.activeSources.find(
          (x) => x.source == result[i].type && x.stockFileId == result[i].id
        );
        result[i].active = find ? true : false;
        if (result[i].sourceType == "stockFile") {
          if (typeof result[i].contents == "string") {
            let data = await axios.get(result[i].contents);
            if (data && data.data) {
              result[i].contents = data.data;
            }
          }
        } else if (result[i].sourceType == "stockFileContainer") {
          for (let j = 0; j < result[i].stockFileContainers.length; j++) {
            if (typeof result[i].stockFileContainers[j].contents == "string") {
              let data = await axios.get(
                result[i].stockFileContainers[j].contents
              );
              if (data && data.data) {
                result[i].stockFileContainers[j].contents = data.data;
              }
            }
          }
        }
      }
      this.availableSources = result;
      this.loadingDataSource = false;
    },
    async getContainerMilestones(container) {
      this.loadingMilestones = true;
      if (!container.containerMilestones) {
        container.containerMilestones = [];
        container.containerMilestones = await this.$API.getContainerMilestones(
          container.id
        );
        this.milestoneKey++;
      }
      this.loadingMilestones = false;
    },
    getIcon(name) {
      let type = name.split(".");
      let result = null;
      if (type.length > 0) {
        result = type.at(-1);
      }
      if (result == "xlsx") {
        result = "xls";
      }
      return `/icons/${result}.svg`;
    },
    getIconColor(state) {
      switch (state) {
        case "Confirmed":
          return "green";
        case "Declined":
          return "red";
        case "Requested":
          return "blue";
        case "Cancelled":
          return "red";
        case "Replaced":
          return "orange";
        case "Amended":
          return "grey";
      }
    },
    openContainer(container) {
      this.$router.push({
        path: "/container/" + container.id + "/" + this.shipment.id,
      });
    },
    invoiceDetails(item) {
      this.invoiceItem = item;
      this.invoiceModal = true;
    },
    async saveContainer() {
      this.savingContainer = true;
      await this.$API.updateContainer(this.container);
      this.containerModal = false;
      this.container = {};
      this.savingContainer = false;
    },
    async saveTempMonitor() {
      await this.$API.updateContainer(this.container);
      this.tempModal = false;
      this.container = {};
    },
    async saveDeckNumber() {
      this.savingDeckNo = true;
      let result = await this.$API.updateContainer({
        id: this.container.id,
        containerNo: this.container.containerNo,
      });
      this.deckModal = false;
      this.container = {};
      this.savingDeckNo = false;
    },
    async saveInvoice() {
      this.savingInvoice = true;
      let result = await this.$API.updateContainer({
        id: this.invoiceItem.id,
        invoiceNo: this.invoiceItem.invoiceNo,
        invoiceDate: this.invoiceItem.invoiceDate,
      });
      this.invoiceModal = false;
      this.invoiceItem = {};
      this.savingInvoice = false;
    },
    async trackContainer(container) {
      this.container = container;
      this.container.trackType = container.eventUrl.includes("OceanInsight")
        ? "OceanInsight"
        : "DCSA";
      let data = await axios.get(container.eventUrl);
      this.events = data.data;
      this.trackerModal = true;
    },
    trackContainerMetrics(item) {
      this.$refs.containerTrackingDialog.open({ containerReference: item.containerNo, bookingId: item.bookingId });
    },
    async deleteSelectedContainerProduct() {
      let find = this.container.containerProducts.findIndex(
        (x) => x.id == this.containerProduct.id
      );
      if (find > -1) {
        // await this.$API.updateContainerProduct({
        //   id: this.container.selectedProduct.id,
        //   isActive: false,
        //   isDeleted: true
        // });
        this.container.containerProducts.splice(find, 1);
      }
      this.containerProductModal = false;
      this.$message.success("Successfully removed product!");
    },
    selectContainer(item) {
      let findIndex = this.selectedContainers.findIndex((x) => x.id == item.id);
      if (findIndex == -1) {
        this.selectedContainers.push(item);
      } else {
        this.selectedContainers.splice(findIndex, 1);
      }
    },
    async updateSelectedContainerProduct(item) {
      let find = this.container.containerProducts.findIndex(
        (x) => x.id == this.containerProduct.id
      );
      this.container.containerProducts[find] = item;
      this.containerProductModal = false;
      if (item.id) {
        await this.$API.updateShipmentContainerProduct(item);
      } else {
        item = {
          ...item,
          shipmentId: this.shipment.id,
          bookingContainerId: this.container.id,
          productId: item.product.id,
        };
        let result = await this.$API.createShipmentContainerProduct(item);
        this.container.containerProducts.push({ ...item, ...result });
      }
      this.containerProductt = {};
      this.$emit("refreshContainers");
      this.$message.success("Successfully updated container product!");
    },
    metadataValue(type, metadata) {
      let find = metadata.find((x) => x.key == type);
      return find.value;
    },
    checkMetadata(type, metadata) {
      let find = metadata.find((x) => x.key == type);
      return find ? true : false;
    },
    verify(type, metadata) {
      let find = metadata.find((x) => x.key == type);
      return find ? find.value == "Passed" : false;
    },
    async updateDataSource() {
      this.$confirm(
        `Are you sure you want to update the data source ?`,
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      ).then(async () => {
        try {
          this.savingDataSource = true;
          let active = this.availableSources.filter((x) => x.active);
          console.log(active);
          let obj = active.map((x) => ({
            source: x.type,
            type: x.type,
            id: x.id,
            sourceType: x.sourceType,
            contents:
              x.sourceType == "stockFileContainer"
                ? x.stockFileContainers[0].contents
                : x.contents,
          }));
          await this.$API.updateDocumentSource({
            bookingContainerId: this.container.id,
            source: obj,
          });
          this.dataSourceChange = false;
          this.savingDataSource = false;
          this.$emit("refreshContainers");
          // this.load();
          this.$message.success("Successfully updated data source!");
        } catch (e) {
          console.log(e);
          this.$message({
            type: "error",
            message: "There was an error changing the document source.",
          });
          this.savingDataSource = false;
        }
      });
    },
    viewContainerModal(item) {
      this.container = item;
      this.getAvailableSources();
      this.containerModal = true;
    },
    viewSource(item) {
      this.sourceItem = item;
      if (this.sourceItem.sourceType == "stockFileContainer") {
        this.selectedContainer = this.sourceItem.stockFileContainers[0];
      }
      this.sourceModal = true;
    },
  },
};
</script>
<style scoped></style>
