<template>
    <div>
        <v-card flat>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="3" md="2">
                        <v-list rounded dense id="upload-options">
                            <v-list-item-group v-model="selectedType" color="primary">

                                <v-list-item v-for="(folderType, i) in selectableTypes" :key="i" :value="folderType.value">
                                    <v-list-item-action>
                                        <v-icon :color="folderType.color">{{ folderType.icon }}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title class="primaryText--text" style="z-index: 2">
                                            {{ folderType.name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle
                                            v-if="folderType.value == 'Draft Documents' && documentCount.draft">
                                            {{ documentCount.draft }} Document(s)
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle
                                            v-if="folderType.value == 'Final Documents' && documentCount.final">
                                            {{ documentCount.final }} Document(s)
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle
                                            v-if="folderType.value == 'System Documents' && documentCount.system">
                                            {{ documentCount.system }} Document(s)
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                        <v-list nav shaped dense style="max-height: 50vh;overflow-y: auto" :key="documentKey" subheader
                            v-if="fileList.length > 0">
                            <v-divider></v-divider>
                            <v-subheader style="font-size: 16px">Uploads</v-subheader>
                            <v-divider></v-divider>
                            <v-list-item v-for="(file, i) in fileList" :key="file.id">
                                <v-list-item-action style="width: 25px" class="mx-0 px-0 pr-1">
                                    <v-img contain style="width: 20px; height: 20px" :src="getIcon(file.file.name)">
                                    </v-img>
                                </v-list-item-action>
                                <v-list-item-content class="ml-0 pl-0">
                                    <v-list-item-title style="font-size: 12px">
                                        {{ file.file.name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle style="font-size: 12px">
                                        {{ file.file.type }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action :key="file.progress">
                                    <v-btn v-if="!file.progress || file.progress == 0" icon color="red"
                                        @click="fileList.splice(i, 1)">
                                        <v-icon>close</v-icon>
                                    </v-btn>
                                    <span v-else-if="file.progress < 100">
                                        {{ file.progress }}%
                                    </span>
                                    <span v-else-if="file.progress == 100">
                                        <v-icon color="green" class="mr-1">check</v-icon>
                                    </span>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="12" sm="9" md="10">
                        <v-card flat class="mt-0" style="height: 60vh">
                            <v-toolbar flat color="transparent">
                                <v-toolbar-title id="upload-menu">
                                    <span v-for="(subitem, index) in menuHistory" :key="subitem.id">
                                        <v-icon v-if="index != 0" class="mx-1" color="grey"> chevron_right
                                        </v-icon>
                                        <v-btn rounded text
                                            v-if="index < (menuHistory.length - 1) || selectedType != 'My Documents'"
                                            :disabled="index == (menuHistory.length - 1) && selectedType != 'My Documents'"
                                            @click="setMenuPosition(subitem, index)">
                                            {{ subitem.name }}
                                            <v-icon v-if="subitem.shared" small class="mx-1">people</v-icon>
                                        </v-btn>
                                        <v-menu v-else offset-y class="mt-0 pt-0" style="border-radius: 35px">
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" text rounded>
                                                    {{ subitem.name }}
                                                    <v-icon v-if="subitem.shared" small class="mx-1">people</v-icon>
                                                    <v-icon right>arrow_drop_down</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item v-for="(item, index) in menuItems" :key="index"
                                                    @click="clickAction(item.action)">
                                                    <v-list-item-action>
                                                        <v-icon :color="item.iconColor">{{ item.icon }}</v-icon>
                                                    </v-list-item-action>
                                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </span>
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-menu v-if="selectedFileList.length > 0">
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" color="primary">
                                            Actions
                                            <v-icon right>arrow_drop_down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item v-for="(item, index) in massActions" :key="index"
                                            @click="clickAction(item.action)">
                                            <v-list-item-action>
                                                <v-icon>{{ item.icon }}</v-icon>

                                            </v-list-item-action>
                                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <v-btn v-if="selectedType == 'Suggested Documents' && selectedSuggestions.length > 0"
                                    color="primary" class="mr-3" outlined rounded small :loading="savingSuggestedDocuments"
                                    @click="saveSuggestedDocuments()">
                                    <v-icon>add</v-icon> Add to Shipment
                                </v-btn>
                                <v-chip style="height: 40px"
              outlined
              small>
              <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon id="create-merged" v-bind="attrs" v-on="on"
                          @click="createMergedDocument()"><v-icon>picture_as_pdf</v-icon></v-btn>
                      </template>
                      <span style="font-size: 12px">Create Merged Document</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon id="create-merged" v-bind="attrs" v-on="on"
                          @click="refresh()"><v-icon>refresh</v-icon></v-btn>
                      </template>
                      <span style="font-size: 12px">Refresh</span>
                    </v-tooltip>
                  <v-text-field
                placeholder="Search"
                class="mb-1"
                hide-details
                rounded
                clearable
                dense
                style="width: 300px"
                v-model="search"
              ></v-text-field>
                </v-chip>
                                <!-- <el-input id="search-upload" style="max-width: 400px" v-model="search"
                                    suffix-icon="el-icon-search" clearable placeholder="Search">
                                    <template slot="prepend">
                                        <el-button id="create-merged"
                                            @click="createMergedDocument()"><v-icon>picture_as_pdf</v-icon></el-button>
                                    </template>
                                    <template slot="prepend">
                                        <el-button @click="refresh()">
                                            <v-icon>refresh</v-icon>
                                        </el-button>
                                    </template>
                                </el-input> -->
                            </v-toolbar>

                            <v-card-text class="my-0 py-0" style="height: 88%" :key="folderKey">
                                <v-progress-linear v-if="loadingFiles" indeterminate color="primary"></v-progress-linear>
                                <v-container
                                    v-if="!loadingFiles && folderContents.length == 0 && selectedType == 'My Documents'"
                                    fill-height style="width: 100%; cursor: pointer;" @click="addFiles"
                                    @contextmenu="showMenuOptions">
                                    <v-row style="height: 100%" align="center" justify="center">
                                        <v-card flat class="text-center" style="height:100%; width: 100%">
                                            <v-container fill-height>
                                                <v-row justify="center" align="center" height="100%" width="100%">
                                                    <v-col>
                                                        <v-icon size="64px" color="grey">cloud_upload</v-icon>
                                                        <v-card-text>
                                                            <p style="color: grey; font-size: 18px">Drop files or click
                                                                here to upload</p>
                                                        </v-card-text>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-row>
                                </v-container>
                                <v-container
                                    v-if="!loadingFiles && folderContents.length == 0 && selectedType == 'Shared Documents'"
                                    fill-height style="width: 100%; cursor: pointer;">
                                    <v-row style="height: 100%" align="center" justify="center">
                                        <v-card flat class="text-center" style="height:100%; width: 100%">
                                            <v-container fill-height>
                                                <v-row justify="center" align="center" height="100%" width="100%">
                                                    <v-col>
                                                        <v-icon size="64px" color="grey">groups</v-icon>
                                                        <v-card-text>
                                                            <p style="color: grey; font-size: 18px">No Shared Files or
                                                                Folders</p>
                                                        </v-card-text>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-row>
                                </v-container>
                                <v-container
                                    v-if="!loadingFiles && folderContents.length == 0 && sharedDocuments.length == 0 && (selectedType == 'Draft Documents' || selectedType == 'Final Documents')"
                                    fill-height style="width: 100%; cursor: pointer;">
                                    <v-row style="height: 100%" align="center" justify="center">
                                        <v-card flat class="text-center" style="height:100%; width: 100%">
                                            <v-container fill-height>
                                                <v-row justify="center" align="center" height="100%" width="100%">
                                                    <v-col>
                                                        <v-icon size="64px" color="grey">description</v-icon>
                                                        <v-card-text>
                                                            <p style="color: grey; font-size: 18px">No {{ selectedType }}
                                                                found.</p>
                                                        </v-card-text>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-row>
                                </v-container>
                                <v-data-table id="upload-table"
                                    v-else-if="!loadingFiles && folderContents.length > 0 && selectedType == 'My Documents'"
                                    :headers="contentHeaders" :search="search" :items="folderContents" fixed-header
                                    show-select v-model="selectedFileList" color="primary" item-key="id" height="55vh"
                                    style="cursor: pointer" hide-default-footer disable-pagination
                                    @contextmenu:row="viewTableMenu" @dblclick:row="doubleClickRow">
                                    <template v-slot:[`item.icon`]="{ item }">
                                        <v-row justify="center">
                                            <v-icon
                                                v-if="item.settingType == 'folder' && (!item.shipmentFolderShareds || (item.shipmentFolderShareds && item.shipmentFolderShareds.length == 0))"
                                                color="amber">folder</v-icon>
                                            <v-icon
                                                v-else-if="item.settingType == 'folder' && ((item.shipmentFolderShareds && item.shipmentFolderShareds.length > 0))"
                                                color="amber">folder_shared</v-icon>

                                            <v-img contain v-else-if="getIcon(item.name)" style="width: 20px; height: 20px"
                                                :src="getIcon(item.name)">
                                            </v-img>
                                        </v-row>
                                    </template>
                                    <template id="table-row" v-slot:[`item.children`]="{ item }">
                                        <span v-if="item.children || item.settingType == 'folder'">{{ item.children }}
                                            items</span>
                                        <span v-else-if="item.size">{{ $Format.formatFileSize(item.size) }}</span>
                                    </template>
                                    <template v-slot:[`item.updatedAt`]="{ item }">
                                        {{ formatDate(item.updatedAt) }}
                                    </template>

                                    <template v-slot:[`item.containers`]="{ item }">
                                        <v-chip v-if="item.shipmentContainerDocuments" @click="viewLinkedContainers(item)" :key="containerDocumentKey">
                                            {{ linkedContainers(item.shipmentContainerDocuments).length }}
                                        </v-chip>
                                    </template>
                                    <template v-slot:[`item.shared`]="{ item }">
                                        <div v-if="item.shipmentFolderShareds && item.shipmentFolderShareds.length > 0">
                                            <v-tooltip top v-for="shared in item.shipmentFolderShareds" :key="shared.id">
                                                <template v-slot:activator="{ on }">
                                                    <v-badge avatar overlap bottom offset-y="15" offset-x="20"
                                                        :color="shared.type != 'approval_required' ? getFriendlyShareTypeColour(shared.type) : shared.approved ? 'green' : shared.approved == null ? 'orange' : 'red'"
                                                        :icon="shared.type != 'approval_required' ? getFriendlyShareTypeIcon(shared.type) : shared.approved ? 'check' : shared.approved == null ? 'pending' : 'cancel'">
                                                        <v-avatar @click="viewSharedItem(item)" v-on="on" class="mx-1"
                                                            size="40"
                                                            :color="shared.sharedOrganisation.logo ? 'white' : 'secondary'">
                                                            <v-img contain v-if="shared.sharedOrganisation.logo"
                                                                :src="shared.sharedOrganisation.logo"></v-img>
                                                            <span v-else class="primaryText--text text-h5">{{
                                                                shared.sharedOrganisation.name.charAt(0)
                                                            }}</span>
                                                        </v-avatar>
                                                    </v-badge>
                                                </template>
                                                <span style="font-size: 12px">{{ shared.sharedOrganisation.name }}: {{
                                                    getFriendlyShareType(shared.type) }} <span
                                                        v-if="shared.type == 'approval_required'">{{
                                                            shared.approved ? '(Approved)' : shared.approved == null ?
                                                            '(Pending)' :
                                                            '(Rejected)' }}</span> </span>
                                            </v-tooltip>
                                        </div>
                                    </template>
                                    <template v-slot:[`item.draftFinal`]="{ item }">
                                        <div :key="markKey">
                                            <v-chip v-if="item.isDraftDoc" color="blue" class="mx-1" small outlined><v-icon
                                                    small left>edit_note</v-icon> Draft</v-chip>
                                            <v-chip v-if="item.isFinalDoc" color="success" class="mx-1" small
                                                outlined><v-icon small left>task_alt</v-icon> Final</v-chip>
                                        </div>
                                    </template>
                                    <!-- <template v-slot:[`item.classification`]="{ item }">
                                        <v-chip @click="addClassification(item)" v-if="uniqueClassifications(item) > 0">{{
                                            uniqueClassifications(item)
                                        }}</v-chip>
                                        
                                    </template> -->
                                    <template v-slot:[`item.classification`]="{ item }">
                                        <v-row v-if="documentClassifications(item).length <= 3" justify="center">
                                            <v-chip small label outlined
                                                v-for="docClassification in documentClassifications(item)"
                                                :key="docClassification.id">
                                                <v-icon small left>label</v-icon> {{ docClassification.name }}
                                            </v-chip>
                                        </v-row>
                                        <v-row v-else justify="center">
                                            <v-chip small label outlined
                                                v-for="docClassification in documentClassifications(item).slice(0, 3)"
                                                :key="docClassification.id">
                                                <v-icon small left>label</v-icon> {{ docClassification.name }}
                                            </v-chip>
                                            <span> + {{ documentClassifications(item).length - 1 }} other(s)</span>
                                        </v-row>

                                    </template>
                                </v-data-table>

                                <v-data-table id="upload-table" v-else-if="selectedType == 'Suggested Documents'"
                                    :headers="suggestedDocumentHeaders" :search="search" :items="suggestions" fixed-header
                                    :loading="loadingSuggestions" v-model="selectedSuggestions" color="primary"
                                    item-key="id" height="55vh" style="cursor: pointer" hide-default-footer
                                    disable-pagination show-select @dblclick:row="doubleClickRow">
                                    <template v-slot:[`item.icon`]="{ item }">
                                        <v-row justify="center" :key="loadingKey">
                                            <v-progress-circular size="16" color="primary" indeterminate
                                                v-if="loadingItems.includes(item.id)"></v-progress-circular>
                                            <v-icon v-else-if="item.settingType == 'folder'" color="amber">folder</v-icon>
                                            <v-img contain v-else-if="getIcon(item.name)" style="width: 20px; height: 20px"
                                                :src="getIcon(item.name)">
                                            </v-img>
                                        </v-row>
                                    </template>
                                    <template v-slot:[`item.children`]="{ item }">
                                        <span v-if="item.size">{{ $Format.formatFileSize(item.size) }}</span>
                                    </template>
                                    <template v-slot:[`item.updatedAt`]="{ item }">
                                        {{ formatDate(item.updatedAt) }}
                                    </template>
                                    <template v-slot:[`item.classification`]="{ item }">
                                        <v-row v-if="documentClassifications(item).length <= 3">
                                            <v-chip small label outlined
                                                v-for="docClassification in documentClassifications(item)"
                                                :key="docClassification.id">
                                                <v-icon small left>label</v-icon> {{ docClassification.name }}
                                            </v-chip>
                                        </v-row>
                                        <v-row v-else>
                                            <v-chip small label outlined
                                                v-for="docClassification in documentClassifications(item).slice(0, 3)"
                                                :key="docClassification.id">
                                                <v-icon small left>label</v-icon> {{ docClassification.name }}
                                            </v-chip>
                                            <span> + {{ documentClassifications(item).length - 1 }} other(s)</span>
                                        </v-row>

                                    </template>
                                    <template v-slot:[`item.linkedContainers`]="{ item }">
                                        <span v-if="item.linkedContainers.filter(Boolean).length > 0">{{
                                            item.linkedContainers.filter(Boolean).length }}</span>
                                    </template>
                                    <template v-slot:[`item.linkedPallets`]="{ item }">
                                        <span v-if="item.linkedPallets.filter(Boolean).length > 0">{{
                                            item.linkedPallets.filter(Boolean).length }}</span>
                                    </template>
                                </v-data-table>

                                <v-data-table id="upload-table"
                                    v-else-if="!loadingFiles && folderContents.length > 0 && selectedType == 'Shared Documents'"
                                    :headers="sharedHeaders" :search="search" :items="folderContents" fixed-header
                                    color="primary" item-key="id" height="55vh" style="cursor: pointer" hide-default-footer
                                    disable-pagination @contextmenu:row="viewTableMenu" @dblclick:row="doubleClickRow">
                                    <template v-slot:[`item.icon`]="{ item }">
                                        <v-row justify="center" :key="loadingKey">
                                            <v-progress-circular size="16" color="primary" indeterminate
                                                v-if="loadingItems.includes(item.id)"></v-progress-circular>
                                            <v-icon v-else-if="item.settingType == 'folder'" color="amber">folder</v-icon>
                                            <v-img contain v-else-if="getIcon(item.name)" style="width: 20px; height: 20px"
                                                :src="getIcon(item.name)">
                                            </v-img>
                                        </v-row>
                                    </template>
                                    <template v-slot:[`item.children`]="{ item }">
                                        <span v-if="item.children || item.settingType == 'folder'">{{ item.children }}
                                            items</span>
                                        <span v-else-if="item.size">{{ $Format.formatFileSize(item.size) }}</span>
                                    </template>
                                    <template v-slot:[`item.updatedAt`]="{ item }">
                                        {{ formatDate(item.updatedAt) }}
                                    </template>
                                    <template v-slot:[`item.shareType`]="{ item }">
                                        <v-tooltip top v-if="item.shareType != 'approval_required'">
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" :color="getFriendlyShareTypeColour(item.shareType)">{{
                                                    getFriendlyShareTypeIcon(item.shareType) }}</v-icon>
                                            </template>
                                            {{ getFriendlyShareType(item.shareType) }}
                                        </v-tooltip>
                                        <v-tooltip top v-else>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on"
                                                    :color="item.approved == null ? 'orange' : item.approved ? 'success' : 'error'">{{
                                                        getFriendlyShareTypeIcon(item.shareType) }}</v-icon>
                                            </template>
                                            {{ getFriendlyShareType(item.shareType) + ': ' +
                                                (item.approved ? 'Approved' : item.approved == null ? 'Pending' : 'Rejected') }}
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:[`item.shared`]="{ item }">
                                        <div v-if="item.organisation">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-avatar v-on="on" class="mx-1" size="32"
                                                        :color="item.organisation.logo ? 'white' : 'secondary'">
                                                        <v-img contain v-if="item.organisation.logo"
                                                            :src="item.organisation.logo"></v-img>
                                                        <span v-else class="primaryText--text text-h5">{{
                                                            item.organisation.name.charAt(0)
                                                        }}</span>
                                                    </v-avatar>
                                                </template>
                                                <span style="font-size: 12px">{{ item.organisation.name }}</span>
                                            </v-tooltip>
                                            <v-chip class="ml-1" small outlined><v-icon small left>person</v-icon> {{
                                                item.createdBy.firstname }} {{ item.createdBy.surname }}</v-chip>
                                        </div>
                                    </template>
                                </v-data-table>

                                <v-data-table id="upload-table"
                                    v-else-if="(!loadingFiles && folderContents.length > 0) && (selectedType == 'Draft Documents' || selectedType == 'Final Documents')"
                                    show-select v-model="selectedFileList"
                                    :headers="selectedType == 'Draft Documents' ? draftHeaders : finalHeaders"
                                    :search="search" :items="folderContents" fixed-header color="primary" item-key="id"
                                    height="55vh" style="cursor: pointer" hide-default-footer disable-pagination
                                    @contextmenu:row="viewTableMenu" @dblclick:row="doubleClickRow">
                                    <template v-slot:[`item.icon`]="{ item }">
                                        <v-row justify="center" :key="loadingKey">
                                            <v-progress-circular size="16" color="primary" indeterminate
                                                v-if="loadingItems.includes(item.id)"></v-progress-circular>
                                            <v-icon v-else-if="item.settingType == 'folder'" color="amber">folder</v-icon>
                                            <v-img contain v-else-if="getIcon(item.name)" style="width: 20px; height: 20px"
                                                :src="getIcon(item.name)">
                                            </v-img>
                                        </v-row>
                                    </template>
                                    <template v-slot:[`item.action`]="{ item }">
                                        <v-chip small color="red" outlined :loading="item.loading"
                                            @click="removeFromDrafts(item)"><v-icon small>close</v-icon> Remove</v-chip>
                                    </template>
                                    <template v-slot:[`item.finalAction`]="{ item }">
                                        <v-chip small color="red" outlined :loading="item.loading"
                                            @click="removeFromFinal(item)"><v-icon small>close</v-icon> Remove</v-chip>
                                    </template>
                                    <template v-slot:[`item.size`]="{ item }">
                                        <span v-if="item.size">{{ $Format.formatFileSize(item.size) }}</span>
                                    </template>
                                    <template v-slot:[`item.draftCreatedBy`]="{ item }">
                                        <v-chip small outlined v-if="item.draftCreatedBy">
                                            <v-icon small left>person</v-icon> {{ item.draftCreatedBy.firstname }} {{
                                                item.draftCreatedBy.surname }}: {{ item.draftCreatedDate }}
                                        </v-chip>
                                    </template>
                                    <template v-slot:[`item.finalCreatedBy`]="{ item }">
                                        <v-chip small outlined v-if="item.finalCreatedBy">
                                            <v-icon small left>person</v-icon> {{ item.finalCreatedBy.firstname }} {{
                                                item.finalCreatedBy.surname }}: {{ item.finalCreatedDate }}
                                        </v-chip>
                                    </template>
                                    <template v-slot:[`item.updatedAt`]="{ item }">
                                        {{ formatDate(item.updatedAt) }}
                                    </template>

                                    <template v-slot:[`item.shared`]="{ item }">
                                        <div v-if="item.shipmentFolderShareds && item.shipmentFolderShareds.length > 0">
                                            <v-tooltip top v-for="shared in item.shipmentFolderShareds" :key="shared.id">
                                                <template v-slot:activator="{ on }">
                                                    <v-badge avatar overlap bottom offset-y="15" offset-x="20"
                                                        :color="shared.type != 'approval_required' ? getFriendlyShareTypeColour(shared.type) : shared.approved ? 'green' : shared.approved == null ? 'orange' : 'red'"
                                                        :icon="shared.type != 'approval_required' ? getFriendlyShareTypeIcon(shared.type) : shared.approved ? 'check' : shared.approved == null ? 'pending' : 'cancel'">
                                                        <v-avatar @click="viewSharedItem(item)" v-on="on" class="mx-1"
                                                            size="40"
                                                            :color="shared.sharedOrganisation.logo ? 'white' : 'secondary'">
                                                            <v-img contain v-if="shared.sharedOrganisation.logo"
                                                                :src="shared.sharedOrganisation.logo"></v-img>
                                                            <span v-else class="primaryText--text text-h5">{{
                                                                shared.sharedOrganisation.name.charAt(0)
                                                            }}</span>
                                                        </v-avatar>
                                                    </v-badge>
                                                </template>
                                                <span style="font-size: 12px">{{ shared.sharedOrganisation.name }}: {{
                                                    getFriendlyShareType(shared.type) }} <span
                                                        v-if="shared.type == 'approval_required'">{{
                                                            shared.approved ? '(Approved)' : shared.approved == null ?
                                                            '(Pending)' :
                                                            '(Rejected)' }}</span> </span>
                                            </v-tooltip>
                                        </div>
                                    </template>
                                </v-data-table>
                                <v-subheader style="font-size: 16px"
                                    v-if="!loadingFiles && sharedDocuments.length > 0 && (selectedType == 'Draft Documents' || selectedType == 'Final Documents')">
                                    <v-icon size="24px" class="mr-1">share</v-icon>
                                    Shared {{
                                        selectedType }}</v-subheader>
                                <v-data-table id="upload-table"
                                    v-if="(!loadingFiles && sharedDocuments.length > 0) && (selectedType == 'Draft Documents' || selectedType == 'Final Documents')"
                                    :headers="sharedHeaders" :search="search" :items="sharedDocuments" fixed-header
                                    color="primary" item-key="id" height="55vh" style="cursor: pointer" hide-default-footer
                                    disable-pagination @contextmenu:row="viewTableMenu" @dblclick:row="doubleClickRow">
                                    <template v-slot:[`item.icon`]="{ item }">
                                        <v-row justify="center" :key="loadingKey">
                                            <v-progress-circular size="16" color="primary" indeterminate
                                                v-if="loadingItems.includes(item.id)"></v-progress-circular>
                                            <v-icon v-else-if="item.settingType == 'folder'" color="amber">folder</v-icon>
                                            <v-img contain v-else-if="getIcon(item.name)" style="width: 20px; height: 20px"
                                                :src="getIcon(item.name)">
                                            </v-img>
                                        </v-row>
                                    </template>
                                    <template v-slot:[`item.shareType`]="{ item }">
                                        <v-tooltip top v-if="item.shareType != 'approval_required'">
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" :color="getFriendlyShareTypeColour(item.shareType)">{{
                                                    getFriendlyShareTypeIcon(item.shareType) }}</v-icon>
                                            </template>
                                            {{ getFriendlyShareType(item.shareType) }}
                                        </v-tooltip>
                                        <v-tooltip top v-else>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on"
                                                    :color="item.approved == null ? 'orange' : item.approved ? 'success' : 'error'">{{
                                                        getFriendlyShareTypeIcon(item.shareType) }}</v-icon>
                                            </template>
                                            {{ getFriendlyShareType(item.shareType) + ': ' +
                                                (item.approved ? 'Approved' : item.approved == null ? 'Pending' : 'Rejected') }}
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:[`item.children`]="{ item }">
                                        <span v-if="item.size">{{ $Format.formatFileSize(item.size) }}</span>
                                    </template>
                                    <template v-slot:[`item.draftCreatedBy`]="{ item }">
                                        <v-chip small outlined v-if="item.draftCreatedBy">
                                            <v-icon small left>person</v-icon> {{ item.draftCreatedBy.firstname }} {{
                                                item.draftCreatedBy.surname }}: {{ item.draftCreatedDate }}
                                        </v-chip>
                                    </template>
                                    <template v-slot:[`item.finalCreatedBy`]="{ item }">
                                        <v-chip small outlined v-if="item.finalCreatedBy">
                                            <v-icon small left>person</v-icon> {{ item.finalCreatedBy.firstname }} {{
                                                item.finalCreatedBy.surname }}: {{ item.finalCreatedDate }}
                                        </v-chip>
                                    </template>
                                    <template v-slot:[`item.updatedAt`]="{ item }">
                                        {{ formatDate(item.updatedAt) }}
                                    </template>

                                    <template v-slot:[`item.shared`]="{ item }">
                                        <div v-if="item.organisation">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-avatar v-on="on" class="mx-1" size="32"
                                                        :color="item.organisation.logo ? 'white' : 'secondary'">
                                                        <v-img contain v-if="item.organisation.logo"
                                                            :src="item.organisation.logo"></v-img>
                                                        <span v-else class="primaryText--text text-h5">{{
                                                            item.organisation.name.charAt(0)
                                                        }}</span>
                                                    </v-avatar>
                                                </template>
                                                <span style="font-size: 12px">{{ item.organisation.name }}</span>
                                            </v-tooltip>
                                            <v-chip class="ml-1" small outlined><v-icon small left>person</v-icon> {{
                                                item.createdBy.firstname }} {{ item.createdBy.surname }}</v-chip>
                                        </div>
                                    </template>
                                </v-data-table>
                                <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y>
                                    <v-list dense>
                                        <v-list-item v-for="(item, index) in menuItems" :key="index"
                                            @click="clickAction(item.action)">
                                            <v-list-item-action>
                                                <v-icon :color="item.iconColor">{{ item.icon }}</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <v-menu v-model="tableMenu" :position-x="x" :position-y="y" absolute offset-y>
                                    <v-list style="background-color: var(--v-background-base) !important;" dense>
                                        <v-list-item v-for="(item, index) in filterAllTableMenuItems" :key="index"
                                            @click="clickAction(item.action)">
                                            <v-list-item-action>
                                                <v-icon :color="item.iconColor">{{ item.icon }}</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <file-upload v-if="selectedType == 'My Documents'" class="my-0 py-0" post-action="" :multiple="true"
                    :drop="true" :drop-directory="true" v-model="fileList" ref="uploader">
                </file-upload>
            </v-card-text>
        </v-card>

        <v-dialog v-model="folderModal" width="400px">
            <v-card v-if="folderModal">
                <v-card-title>
                    <v-icon class="mr-1">create_new_folder</v-icon> Create Folder <v-spacer></v-spacer> <v-btn text
                        @click="folderModal = false, savingFolder = false, item = {}">X</v-btn>
                </v-card-title>
                <v-card-text>
                    <v-text-field @keydown.enter="saveFolder" autofocus outlined dense clearable label="Folder Name"
                        v-model="item.name"></v-text-field>
                    <v-row justify="center">
                        <v-btn color="primary" :disabled="!item.name" text :loading="savingFolder" @click="saveFolder">
                            Create</v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="previewModal" width="1200px" :fullscreen="fullscreen || $vuetify.breakpoint.mobile">
            <v-card v-if="item && item.id">
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>
                        {{ item.name }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn v-if="item && item.shareType == 'approval_required' && item.approved == null"
                        @click="approveDocument(item, true)" :loading="approvingDocument" outlined color="success"
                        class="mx-1"><v-icon>check</v-icon> Approve</v-btn>
                    <v-btn v-if="item && item.shareType == 'approval_required' && item.approved == null"
                        @click="approveDocument(item, false)" :loading="approvingDocument" outlined color="red"
                        class="mx-1"><v-icon>close</v-icon> Reject</v-btn>

                    <v-btn @click="deleteDocument(item)" v-if="shipment.documentationStatus == 'OPEN'" icon
                        color="red"><v-icon>delete</v-icon></v-btn>
                    <v-btn @click="downloadDocument(item)" icon><v-icon>download</v-icon></v-btn>
                    <v-btn @click="fullscreen = !fullscreen" icon><v-icon v-if="!fullscreen">fullscreen</v-icon><v-icon
                            v-else>fullscreen_exit</v-icon></v-btn>
                    <v-btn icon text @click="previewModal = false, item = {}">X</v-btn>
                </v-toolbar>
                <v-card-subtitle v-if="item.shareType == 'approval_required' && item.approved != null">
                    <v-icon v-if="item.approved" color="success" class="mr-2">task_alt</v-icon><v-icon v-else color="red"
                        class="mr-2">cancel</v-icon> File {{ item.approved ? 'approved' : 'rejected' }} by {{
                            item.approvedBy.firstname }} {{ item.approvedBy.surname }} on {{ formatDate(item.approvedAt) }}
                </v-card-subtitle>
                <v-card-text :style="{ 'max-height': fullscreen ? '90vh' : '75vh', 'overflow-y': 'auto' }">
                    <v-row>
                        <v-col cols="12" :loading="imageLoading">
                            <div v-if="item.type && item.type.includes('image/')">
                                <v-img :src="item.url" contain style="max-height: 70vh" @load="imageLoading = false">
                                </v-img>
                            </div>
                            <div v-else-if="item.type == 'application/pdf'">
                                <v-row justify="center">
                                    <v-col cols="12" sm="9" class="text-center" v-if="item.url">
                                        <div ref="pdfBox">
                                            <VuePdfEmbed :width="fullscreen ? 1200 : 900" :source="item.url"
                                                @loaded="imageLoading = false" />
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-container v-else fill-height>
                                <v-row justify="center" height="100%">
                                    <div class="text-center">
                                        <span>This file is in a format that cannot be previewed.</span> <br /><br />
                                        <v-btn color="primary" style="text-transform: none" @click="downloadDocument(item)">
                                            <v-icon class="mr-1">download</v-icon> Download</v-btn>
                                    </div>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="shareDialog" width="1200px" persistent :fullscreen="$vuetify.breakpoint.mobile">
            <v-card v-if="item && item.type && shareDialog">
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>Share</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu offset-y class="mt-0 pt-0" style="border-radius: 35px">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" outlined small style="text-transform: none"
                                :color="getFriendlyShareTypeColour(item.type)">
                                <v-icon left> {{ getFriendlyShareTypeIcon(item.type) }}</v-icon>
                                {{
                                    getFriendlyShareType(item.type) }} <v-icon right>arrow_drop_down</v-icon></v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="(share, index) in shareTypes" :key="index" @click="item.type = share.value">
                                <v-list-item-content>
                                    <v-list-item-title>{{ share.name }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn clss="mx-2" color="primary" text @click="submitShare" :loading="submittingShare">Submit</v-btn>
                    <v-btn @click="shareDialog = false, item = {}, selectedFiles = []" text>X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-divider></v-divider>
                            <v-subheader>You are about to share the following: <v-spacer></v-spacer>
                            </v-subheader>
                            <v-divider></v-divider>
                            <v-list dense v-for="file in selectedFiles" :key="file.id">
                                <v-list-item style="height: 30px">
                                    <v-list-item-action>
                                        <v-icon size="30" v-if="file.settingType == 'folder'" color="amber">folder</v-icon>
                                        <v-img contain v-else-if="getIcon(file.name)" style="width: 30px; height: 30px"
                                            :src="getIcon(file.name)">
                                        </v-img>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ file.name }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn @click="removeFromShareList(file)" v-on="on" color="red"
                                                    icon><v-icon>close</v-icon></v-btn>
                                            </template>
                                            <span style="font-size: 12px">Remove from list</span>
                                        </v-tooltip>

                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-divider></v-divider>
                            <v-subheader>Share with the following parties: <v-spacer></v-spacer>
                                <v-btn v-if="item.organisations.length < availableParties.length"
                                    @click="selectAllOrganisations()" color="blue" outlined small>Select All</v-btn>
                                <v-btn v-else-if="item.organisations.length == availableParties.length"
                                    @click="item.organisations = [], shareKey++" outlined color="red" small>Deselect
                                    All</v-btn>

                            </v-subheader>
                            <v-divider></v-divider>
                            <v-list dense>
                                <v-list-item v-for="party in availableParties" :key="party.value"
                                    @click="changeOrganisation(party.value)">
                                    <v-list-item-action>
                                        <v-avatar :color="party.logo ? 'white' : 'secondary'" size="36">
                                            <v-img contain v-if="party.logo" :src="party.logo"></v-img>
                                            <span v-else class="primaryText--text text-h5">{{
                                                party.name.charAt(0)
                                            }}</span>
                                        </v-avatar>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap" style="font-size: 14px">
                                            {{ party.name }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action :key="shareKey">
                                        <v-icon v-if="item.organisations.includes(party.value)"
                                            color="success">check</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>


                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="sharedItemModal" width="800px" persistent :fullscreen="$vuetify.breakpoint.mobile">
            <v-card v-if="sharedItemModal && item && item.id">
                <v-card-title><v-icon class="mr-2" color="secondary">groups</v-icon> Shared {{ item.settingType ==
                    'folder' ? 'Folder' : 'File' }} <v-spacer></v-spacer> <v-btn text
                        @click="sharedItemModal = false, item = {}, shareList = []">X</v-btn></v-card-title>
                <v-card-subtitle class="mt-1 pt-1">
                    <v-list-item>
                        <v-list-item-action>
                            <v-icon size="20" v-if="item.settingType == 'folder'" color="amber">folder</v-icon>
                            <v-img contain v-else-if="getIcon(item.name)" style="width: 20px; height: 20px"
                                :src="getIcon(item.name)">
                            </v-img>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.name }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </v-card-subtitle>
                <v-card-text>
                    <v-list dense subheader>
                        <v-divider></v-divider>
                        <v-subheader style="font-size: 16px">Shared with the following parties</v-subheader>
                        <v-divider></v-divider>
                        <v-list-item v-if="item.shipmentFolderShareds.length == 0">
                            <v-list-item-content class="text-center">
                                <span style="font-size: 12;color: grey">Currently not shared with anyone</span>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-for="shared in item.shipmentFolderShareds" :key="shared.id">
                            <v-list-item-action>
                                <v-avatar class="mx-1" size="32"
                                    :color="shared.sharedOrganisation.logo ? 'white' : 'secondary'">
                                    <v-img contain v-if="shared.sharedOrganisation.logo"
                                        :src="shared.sharedOrganisation.logo"></v-img>
                                    <span v-else class="primaryText--text text-h5">{{
                                        shared.sharedOrganisation.name.charAt(0)
                                    }}</span>
                                </v-avatar>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ shared.sharedOrganisation.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ getFriendlyShareType(shared.type) }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-row>
                                    <v-tooltip top v-if="shared.type != 'approval_required'">
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" :color="getFriendlyShareTypeColour(shared.type)">{{
                                                getFriendlyShareTypeIcon(shared.type) }}</v-icon>
                                        </template>
                                        {{ getFriendlyShareType(shared.type) }}
                                    </v-tooltip>
                                    <v-tooltip top v-else>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on"
                                                :color="shared.approved == null ? 'orange' : shared.approved ? 'success' : 'error'">{{
                                                    getFriendlyShareTypeIcon(shared.type) }}</v-icon>
                                        </template>
                                        {{ getFriendlyShareType(shared.type) + ': ' +
                                            (shared.approved ? 'Approved' : shared.approved == null ? 'Pending' :
                                                'Rejected') }}
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon v-on="on" color="red"
                                                @click="removeOrganisationFromShareList(shared)"
                                                :loading="shared.loading"><v-icon>close</v-icon></v-btn>
                                        </template>
                                        Remove from sharing
                                    </v-tooltip>
                                </v-row>

                            </v-list-item-action>
                        </v-list-item>
                        <v-divider v-if="availableShareParties.length > 0"></v-divider>
                        <v-subheader v-if="availableShareParties.length > 0" style="font-size: 16px">Add to Share List
                            <v-spacer></v-spacer>

                            <v-menu offset-y class="mt-0 pt-0" style="border-radius: 35px" v-if="shareList.length > 0">
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" outlined small style="text-transform: none"
                                        :color="getFriendlyShareTypeColour(item.shareType)">
                                        <v-icon left> {{ getFriendlyShareTypeIcon(item.shareType) }}</v-icon>
                                        {{
                                            getFriendlyShareType(item.shareType) }} <v-icon
                                            right>arrow_drop_down</v-icon></v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-for="(share, index) in shareTypes" :key="index"
                                        @click="item.shareType = share.value">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ share.name }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-btn color="primary" class="ml-1" small v-if="shareList.length > 0" @click="submitItemShare"
                                :loading="submittingShare">
                                <v-icon left>share</v-icon> Share
                            </v-btn>
                        </v-subheader>
                        <v-divider v-if="availableShareParties.length > 0"></v-divider>
                        <v-list-item v-for="party in availableShareParties" :key="party.value"
                            @click="addToShareList(party.value)">
                            <v-list-item-action>
                                <v-avatar :color="party.logo ? 'white' : 'secondary'" size="36">
                                    <v-img contain v-if="party.logo" :src="party.logo"></v-img>
                                    <span v-else class="primaryText--text text-h5">{{
                                        party.name.charAt(0)
                                    }}</span>
                                </v-avatar>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="font-size: 14px">
                                    {{ party.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action :key="shareKey">
                                <v-icon v-if="shareList.includes(party.value)" color="success">check</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>

                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="classificationModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
            <v-card v-if="documentItem && classificationModal">
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>
                        Classifications
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon color="secondary" :loading="savingClassifications"
                        @click="saveClassifications"><v-icon>save</v-icon></v-btn>
                    <v-btn icon @click="classificationModal = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-subtitle>
                    {{ documentItem.name }}
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <!-- <v-col cols="12" sm="6">
                            <v-subheader style="font-size: 16px">
                                <v-row v-if="!searchMainDocuments">
                                    Primary Documents <v-spacer></v-spacer> <v-btn icon
                                        @click="searchMainDocuments = true"><v-icon>search</v-icon></v-btn>
                                </v-row>
                                <v-text-field v-else placeholder="Search" autofocus outlined dense clearable
                                    v-model="searchMain"></v-text-field>
                            </v-subheader>
                            <v-divider></v-divider>
                            <v-list dense style="max-height: 50vh; overflow-y:auto">
                                <v-list-item v-for="document in filteredMainDocuments" :key="document.id">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ document.name }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-checkbox id="classification-select" v-model="selectedMainDocuments"
                                            color="primary" :value="document.id"></v-checkbox>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item v-if="filteredMainDocuments.length == 0">
                                    <v-list-item-content>
                                        <span style="color: grey; font-size: 14px">No documents found</span>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col> -->
                        <v-col cols="12">
                            <v-subheader style="font-size: 16px">
                                <v-row v-if="!searchSupportingDocuments">
                                    Supporting Documents <v-spacer></v-spacer> <v-btn icon
                                        @click="searchSupportingDocuments = true"><v-icon>search</v-icon></v-btn>
                                </v-row>
                                <v-text-field v-else placeholder="Search" autofocus outlined dense clearable
                                    v-model="searchSupporting"></v-text-field>
                            </v-subheader>
                            <v-divider></v-divider>
                            <v-list dense style="max-height: 50vh; overflow-y:auto">
                                <v-list-item v-for="document in filteredSupportingDocuments" :key="document.id">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ document.name }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-checkbox id="classification-select" v-model="selectedSupportingDocuments"
                                            color="primary" :value="document.id"></v-checkbox>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item v-if="filteredSupportingDocuments.length == 0">
                                    <v-list-item-content>
                                        <span style="color: grey; font-size: 14px">No supporting documents found</span>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>

                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="mergeModal" persistent width="750px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Merge Documents</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="mergeModal = false, mergedDocument = {}">X</v-btn>
        </v-toolbar>
        <v-card-text v-if="mergedDocument.documents" style="min-height: 50vh; max-height: 75vh; overflow-y: auto">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field id="document-name" label="Document Name*" v-model="mergedDocument.name" suffix=".pdf" outlined
                dense></v-text-field>

            </v-col>
            <v-col cols="12" sm="6" class="text-center">
              <v-btn id="submit-merge" @click="mergeDocuments" :loading="loadingMerge"
                :disabled="!mergedDocument.name || !mergedDocument.documents || mergedDocument.documents.length == 0"
                color="primary" text>Submit Merge</v-btn>
            </v-col>

            <v-col cols="12" sm="6" v-if="!loadingMerge">
              <v-list dense subheader id="available-list">
                <v-subheader>Available Documents</v-subheader>
                <v-list-item v-for="available in availableMergeDocuments" :key="available.id">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ available.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ available.pages }} Page(s)
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn id="select-doc" icon color="blue"
                      @click="addMergeDocument(available)"><v-icon>add_circle_outline</v-icon></v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6" v-if="!loadingMerge">
              <v-list dense subheader id="selected-list">
                <v-subheader>Selected Documents</v-subheader>
                <v-list-item v-for="(document, index) in mergedDocument.documents" :key="document.id">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ document.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ document.pages }} Page(s)
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn id="remove-doc" icon color="red"
                      @click="removeMergedDocument(index)"><v-icon>remove_circle_outline</v-icon></v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="mergedDocument.documents.length == 0">
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No documents selected</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card></v-dialog>

        <v-dialog v-model="linkedContainersModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
            <v-card>
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>
                        Linked Containers
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text color="secondary" @click="saveContainerDocuments()"
                        :loading="savingContainerDocuments"><v-icon>save</v-icon></v-btn>
                    <v-btn text @click="linkedContainersModal = false">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-list dense style="max-height: 50vh; overflow-y:auto;">
                        <v-list-item v-for="container in bookings" :key="container.id"
                            @click="selectContainer(container.id)">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ container.containerNo }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ container.ctoNo }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon v-if="selectedContainers.includes(container.id)"
                                    color="success">check_circle_outline</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios'
import dateFormat from 'dateformat';
import FileUpload from "vue-upload-component";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
    props: ['shipment', 'bookings', 'documentTypes'],
    components: {
        FileUpload,
        VuePdfEmbed
    },
    data: () => ({
        loadingMerge:false,
        approvingDocument: false,
        classificationModal: false,
        containerDocumentKey: 6000,
        contentHeaders: [
            { text: '', value: 'icon', align: 'center', sortable: false },
            { text: 'Name', value: 'name' },
            { text: 'Last Modified', value: 'updatedAt', align: 'center' },
            { text: 'Shared', value: 'shared', align: 'center', sortable: false },
            { text: 'Draft/Final', value: 'draftFinal', align: 'center', sortable: false },
            { text: 'Containers', value: 'containers', align: 'center', sortable: false },
            { text: 'Classifications', value: 'classification', align: 'center', sortable: false },
        ],
        sharedHeaders: [
            { text: '', value: 'icon', align: 'center', sortable: false },
            { text: 'Name', value: 'name' },
            { text: 'Share Type', value: 'shareType', align: 'center' },
            { text: 'Size', value: 'children', align: 'center', sortable: false },
            { text: 'Last Modified', value: 'updatedAt', align: 'center' },
            { text: 'Shared By', value: 'shared', align: 'center', sortable: false },
        ],
        draftHeaders: [
            { text: '', value: 'icon', align: 'center', sortable: false },
            { text: 'Action', value: 'action', align: 'center', sortable: false },
            { text: 'Name', value: 'name' },
            { text: 'Size', value: 'size', align: 'center', sortable: false },
            { text: 'Last Modified', value: 'updatedAt', align: 'center' },
            // { text: 'Marked as Draft By', value: 'shared', align: 'center', sortable: false },
            { text: 'Marked as Draft', value: 'draftCreatedBy', align: 'center', sortable: false },
            { text: 'Shared', value: 'shared', align: 'center', sortable: false },
        ],
        finalHeaders: [
            { text: '', value: 'icon', align: 'center', sortable: false },
            { text: 'Action', value: 'finalAction', align: 'center', sortable: false },
            { text: 'Name', value: 'name' },
            { text: 'Size', value: 'size', align: 'center', sortable: false },
            { text: 'Last Modified', value: 'updatedAt', align: 'center' },
            // { text: 'Marked as Draft By', value: 'shared', align: 'center', sortable: false },
            { text: 'Marked as Final', value: 'finalCreatedBy', align: 'center', sortable: false },
            { text: 'Shared', value: 'shared', align: 'center', sortable: false },
        ],
        suggestedDocumentHeaders: [
            { text: '', value: 'icon', align: 'center', sortable: false },
            { text: 'Name', value: 'name' },
            { text: 'Size', value: 'size', align: 'center', sortable: false },
            { text: 'Last Modified', value: 'updatedAt', align: 'center' },
            { text: 'Classifications', value: 'classification', align: 'center', sortable: false },
            { text: 'Linked Containers', value: 'linkedContainers', align: 'center', sortable: false },
            { text: 'Linked Pallets', value: 'linkedPallets', align: 'center', sortable: false },

        ],
        currentFolder: undefined,
        documentKey: 1000,
        documentCount: {
            draft: 0,
            final: 0
        },
        mergedDocument:{},
        mergeModal:false,
        documentItem: null,
        folderModal: false,
        folderDocuments: [],
        folderSignal: null,
        fileList: [],
        folderKey: 2000,
        fullscreen: false,
        imageLoading: false,
        item: null,
        lastIndex: 0,
        linkedContainersModal: false,
        linkedContainersDocument: null,
        loadingFolders: false,
        loadingSharedFolders: false,
        loadingSharedDocuments: false,
        loadingFolderDocuments: false,
        loadingSuggestions: false,
        loadingItems: [],
        loadingKey: 5000,
        markKey: 7000,
        menuItems: [
            { name: 'Add Folder', icon: 'create_new_folder', action: 'create_folder' },
            { name: 'Add File', icon: 'note_add', action: 'upload_file' }
        ],
        menuHistory: [],
        originalFolder: null,
        previewModal: false,
        allTableMenuItems: [
            { name: 'Download', icon: 'download', action: 'download_file', type: 'file' },
            { name: 'View', icon: 'launch', action: 'view_file', type: 'file' },
            { name: 'Share', icon: 'share', action: 'share_file', type: 'file' },
            { name: 'Share', icon: 'share', action: 'share_file', type: 'folder' },
            { name: 'Add Classification', icon: 'category', action: 'add_classification', type: 'file' },
            { name: 'Mark as Draft Document', icon: 'edit_note', iconColor: 'blue', action: 'draft_document', type: 'file' },
            { name: 'Mark as Final Document', icon: 'task_alt', iconColor: 'success', action: 'final_document', type: 'file' },
            { name: 'Delete', icon: 'delete', action: 'delete_file', type: 'file', iconColor: 'red' }
        ],
        savingSuggestedDocuments: false,
        selectedMainDocuments: [],
        selectedSupportingDocuments: [],
        sharedItemModal: false,
        shareTableItems: [
            { name: 'Download', icon: 'download', action: 'download_file', type: 'file', permissions: ['full_access', 'approval_required', 'view_only'] },
            { name: 'View', icon: 'launch', action: 'view_file', type: 'file', permissions: ['full_access', 'approval_required', 'view_only'] },
            { name: 'Share', icon: 'share', action: 'share_file', type: 'file', permissions: ['full_access'] },
            { name: 'Share', icon: 'share', action: 'share_file', type: 'folder', permissions: ['full_access'] },
            { name: 'Delete', icon: 'delete', action: 'delete_file', type: 'file', iconColor: 'red', permissions: ['full_access'] },
            { name: 'Approve File', icon: 'check_circle_outline', action: 'approve_file', type: 'file', iconColor: 'green', permissions: ['approval_required'] },
            { name: 'Reject File', icon: 'cancel', action: 'reject_file', type: 'file', iconColor: 'red', permissions: ['approval_required'] },
            { name: 'Approve Folder', icon: 'check_circle_outline', action: 'approve_file', type: 'folder', iconColor: 'green', permissions: ['approval_required'] },
            { name: 'Reject Folder', icon: 'cancel', action: 'reject_file', type: 'folder', iconColor: 'red', permissions: ['approval_required'] },
            { name: 'Mark as Draft Document', icon: 'edit_note', iconColor: 'blue', action: 'draft_document', type: 'file', permissions: ['approval_required', 'full_access'] },
            { name: 'Mark as Final Document', icon: 'task_alt', iconColor: 'success', action: 'final_document', type: 'file', permissions: ['approval_required', 'full_access'] },
        ],
        tableMenuItem: null,
        tableMenu: false,
        massActions: [
            // { name: 'Download', icon: 'download', action: 'download_file' },
            { name: 'Share', icon: 'share', action: 'share_all' },
            { name: 'Delete', icon: 'delete', action: 'delete_all', iconColor: 'red' }
        ],
        myFolders: [],
        savingClassifications: false,
        savingContainerDocuments: false,
        savingFolder: false,
        sharedFolders: [],
        shareTypes: [
            { name: 'View Only', value: 'view_only', icon: 'visibility', color: 'teal' },
            { name: 'Approval Required', value: 'approval_required', icon: 'fact_check', color: 'orange' },
            { name: 'Full access', value: 'full_access', icon: 'verified', color: 'blue' }
        ],
        search: null,
        searchMainDocuments: false,
        searchSupportingDocuments: false,
        searchMain: null,
        searchSupporting: null,
        selectedFiles: [],
        selectedFileList: [],
        selectedContainers: [],
        selectedSuggestions: [],
        selectedType: null,
        selectableTypes: [
            { name: 'My Documents', value: 'My Documents', icon: 'folder', color: 'amber' },
            { name: 'Shared with me', value: 'Shared Documents', icon: 'folder_shared', color: 'amber' },
            // { name: 'System Documents', value: 'System Documents', icon: 'settings_suggest', color: 'grey' },
            { name: 'Draft Documents', value: 'Draft Documents', icon: 'rule_folder', color: 'blue' },
            { name: 'Final Documents', value: 'Final Documents', icon: 'folder_special', color: 'success' },
        ],
        shareDialog: false,
        shareList: [],
        showMenu: false,
        submittingShare: false,
        suggestions: [],
        shareKey: 4000,
        sharedDocuments: [],
        supportingDocs: [],
        x: null,
        y: null,
    }),
    // created() {

    // },
    async mounted() {
        this.currentFolder = null
        this.selectedType = "My Documents"
        this.getDocumentCount()
    },
    watch: {
        currentFolder() {
            this.getFolderDocuments()
            this.getFolders()
        },
        fileList: {
            immediate: true,
            handler(val) {
                if (val.length > 0) {
                    this.submitFiles();
                }
            },
        },
        selectedType(val) {
            let findType = this.selectableTypes.find(x => x.value == val)
            if (findType) {
                this.menuHistory = [
                    { name: findType.name, id: null }
                ]
            }
            this.selectedFileList = []
            this.getFolderDocuments()
            this.getFolders()
            this.currentFolder = null

        },
        'shipment.id': {
            immediate: true,
            handler(val) {
                if (val) {
                    this.getSuggestedDocuments()
                    this.getSupportingDocs()
                }
            }
        }
    },
    computed: {
        availableMergeDocuments() {
      let result = this.folderDocuments.filter(x => x.type == 'application/pdf')
      if (this.mergedDocument && this.mergedDocument.documents) {
        result = result.filter(x => !this.mergedDocument.documents.map(y => y.id).includes(x.id))
      }
      return result
    },
        availableParties() {
            let result = []
            let parties = ['shipper', 'onBehalfShipper', 'forwarder', 'onBehalfForwarder', 'consignee', 'onBehalfConsignee', 'buyer', 'onBehalfBuyer', 'firstNotify', 'secondNotify', 'courierParty']
            for (let i = 0; i < parties.length; i++) {
                if (this.shipment[parties[i]]) {
                    let findExisting = result.find(x => x.value == this.shipment[parties[i] + 'Id'])
                    if (!findExisting) {
                        result.push({
                            role: parties[i],
                            logo: this.shipment[parties[i]].logo,
                            name: this.shipment[parties[i]].name,
                            value: this.shipment[parties[i] + 'Id']
                        })
                    }
                }
            }
            result = result.filter(x => x.value != this.$store.state.currentOrg.id)
            result.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            return result
        },
        availableShareParties() {
            let result = this.availableParties
            if (this.item && this.item.shipmentFolderShareds) {
                result = result.filter(x => !this.item.shipmentFolderShareds.map(y => y.sharedOrganisationId).includes(x.value))
            }
            return result
        },
        myDraftDocuments() {
            let result = []
            let files = this.folderDocuments.map(file => ({ ...file, settingType: 'file' }))
            result = files
            return result
        },
        myFinalDocuments() {
            let result = []
            let files = this.folderDocuments.map(file => ({ ...file, settingType: 'file' }))
            result = files
            return result
        },
        sharedDraftDocuments() {
            let result = []
            let files = this.folderDocuments.map(file => ({ ...file, settingType: 'file' }))
            result = files
            return result
        },
        filterAllTableMenuItems() {
            let result = []
            if (this.tableMenuItem) {
                if (this.selectedType == "My Documents") {
                    result = this.allTableMenuItems.filter(item => item.type == this.tableMenuItem.settingType)
                    if (this.tableMenuItem.type && !this.tableMenuItem.type.includes('image/') && !this.tableMenuItem.type.includes('application/pdf')) {
                        result = result.filter(item => item.action != 'view_file')
                    }
                } else if (this.selectedType == "Draft Documents" || this.selectedType == "Final Documents") {
                    result = this.allTableMenuItems.filter(item => item.type == this.tableMenuItem.settingType)
                    if (this.tableMenuItem.type && !this.tableMenuItem.type.includes('image/') && !this.tableMenuItem.type.includes('application/pdf')) {
                        result = result.filter(item => item.action != 'view_file')

                    }
                    if (this.tableMenuItem.organisationId != this.$store.state.currentOrg.id) {
                        result = result.filter(item => item.action != 'delete_file' && item.action != 'add_classification')
                    }
                    result = result.filter(item => item.action != 'draft_document' && item.action != 'final_document')

                } else if (this.selectedType == "Shared Documents") {
                    console.log(this.tableMenuItem)
                    result = this.shareTableItems.filter(item => item.type == this.tableMenuItem.settingType && item.permissions.includes(this.tableMenuItem.shareType))
                    if (this.tableMenuItem.type && !this.tableMenuItem.type.includes('image/') && !this.tableMenuItem.type.includes('application/pdf')) {
                        result = result.filter(item => item.action != 'view_file')
                    }
                    result = result.filter(item => item.action != 'add_classification')
                    if (this.tableMenuItem.shareType == 'approval_required' && this.tableMenuItem.approved != null) {
                        result = result.filter(item => item.action != 'approve_file' && item.action != 'reject_file')
                    }
                }
            }
            return result
        },
        folderContents() {
            switch (this.selectedType) {
                case 'My Documents':
                    return this.myFolderContent
                case 'Shared Documents':
                    return this.sharedFolders
                case 'Draft Documents':
                    return this.myDraftDocuments
                case 'Final Documents':
                    return this.myFinalDocuments
                default:
                    return []
            }
        },
        getHeading() {
            switch (this.selectedType) {
                case 'My Documents':
                    return 'My Documents'
                case 'Shared Documents':
                    return 'Shared With Me'
                default:
                    return null
            }
        },
        getTotalItems() {
            switch (this.selectedType) {
                case 'My Documents':
                    return (this.myFolders.length + this.folderDocuments.length) + ' items'
                case 'Shared Documents':
                    return this.sharedFolders.length + ' items'
                default:
                    return null
            }
        },
        loadingFiles() {
            return this.loadingFolderDocuments || this.loadingFolders || this.loadingSharedFolders
        },
        myFolderContent() {
            let result = []
            let folders = this.myFolders.map(folder => ({ ...folder, settingType: 'folder' }))
            let files = this.folderDocuments.map(file => ({ ...file, settingType: 'file' }))
            result = [...folders, ...files]
            return result
        },
        uniqueDocuments() {
            let result = [];
            let uniqueDocuments = [
                ...new Set(this.documentTypes.map((doc) => doc.locationDocument.id)),
            ];
            for (let i = 0; i < uniqueDocuments.length; i++) {
                let find = this.documentTypes.find(
                    (doc) => doc.locationDocument.id == uniqueDocuments[i]
                ).locationDocument;
                result.push(find);
            }
            result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
            if (this.searchClassification) {
                result = result.filter((x) =>
                    x.name.toLowerCase().includes(this.searchClassification.toLowerCase())
                );
            }
            return result;
        },

        filteredMainDocuments() {
            let result = this.uniqueDocuments
            if (this.searchMain) {
                result = result.filter(x => x.name.toLowerCase().includes(this.searchMain.toLowerCase()))
            }
            return result
        },
        filteredSupportingDocuments() {
            let result = this.uniqueSupportingDocuments
            if (this.searchSupporting) {
                result = result.filter(x => x.name.toLowerCase().includes(this.searchSupporting.toLowerCase()))
            }
            return result
        },
        uniqueSupportingDocuments() {
            // let result = [];
            // let uniqueDocuments = this.documentTypes.filter(
            //     (x) =>
            //         x.shipmentDocumentTypeSupportings.length > 0 ||
            //         x.variations.some((y) => y.shipmentDocumentTypeSupportings.length > 0)
            // );
            // let variationDocuments = uniqueDocuments
            //     .map((x) => x.variations.map((y) => y.shipmentDocumentTypeSupportings))
            //     .flat();
            // let supportingDocuments = uniqueDocuments
            //     .map((x) => x.shipmentDocumentTypeSupportings)
            //     .flat();
            // let allSupportingDocuments = [
            //     ...supportingDocuments,
            //     ...variationDocuments,
            // ].flat();
            // let uniqueSupportingDocuments = [
            //     ...new Set(
            //         allSupportingDocuments.map((doc) => doc.supportingDocumentId)
            //     ),
            // ];
            // for (let i = 0; i < uniqueSupportingDocuments.length; i++) {
            //     let find = allSupportingDocuments.find(
            //         (doc) => doc.supportingDocumentId == uniqueSupportingDocuments[i]
            //     ).supportingDocument;
            //     result.push(find);
            // }
            // result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
            let result = this.supportingDocs
            return result;
        },
    },
    // mounted() {
    //     this.getFolders()
    // },
    methods: {
        removeMergedDocument(index) {
      this.mergedDocument.documents.splice(index, 1)
    },
        addMergeDocument(document) {
      this.mergedDocument.documents.push(document)
    },
        createMergedDocument() {
        this.mergedDocument = {
            shipmentId: this.shipment.id,
            name: null,
            documents: []
        }
        this.mergeModal = true
        },
        async mergeDocuments() {
            this.loadingMerge = true
            this.mergedDocument.documents = this.mergedDocument.documents.map(x => ({ id: x.id, key: x.key }))
            console.log('mergedDocument',this.mergedDocument)
            let result = await this.$API.mergeShipmentDocuments(this.mergedDocument)
            this.$emit("getShipmentDocuments");
            this.mergeModal = false
            this.mergedDocument = {}
            this.loadingMerge = false
            this.$message({
                type: "success",
                message: "Successfully merged!",
            });

        },
        addClassification(item) {
            // this.selectedClassifications = [...this.selectedClassifications, {id: null, name: null}]
            this.documentItem = item;
            this.selectedMainDocuments =
                item.shipmentDocumentClassifications
                    .filter((x) => x.locationDocumentId)
                    .map((x) => x.locationDocumentId);
            this.selectedSupportingDocuments =
                item.shipmentDocumentClassifications
                    .filter((x) => x.supportingDocumentId)
                    .map((x) => x.supportingDocumentId);
            this.classificationModal = true
        },
        addFiles() {
            this.$refs.uploader.$el.children[0].click();
        },
        addToShareList(value) {
            if (this.shareList.includes(value)) {
                this.shareList.splice(this.shareList.indexOf(value), 1)
            } else {
                this.shareList.push(value)
            }
            this.shareKey++
        },
        async approveDocument(item, approved) {
            this.approvingDocument = true
            item.approved = approved
            item.approvedBy = {
                firstname: this.$store.state.user.firstname,
                surname: this.$store.state.user.surname
            }
            item.approvedByOrganisation = this.$store.state.currentOrg
            item.approvedAt = new Date()

            let obj = {
                id: item.id,
                approved: approved
            }
            this.loadingItems.push(item.id)
            let result = await this.$API.approveDocument(obj)
            if (item.approved) {
                this.$message.success('Document approved!')
            } else {
                this.$message.success('Document rejected!')
            }
            this.loadingItems.splice(this.loadingItems.indexOf(item.id))
            this.approvingDocument = false
        },
        changeOrganisation(value) {
            if (this.item && this.item.type) {
                if (this.item.organisations.includes(value)) {
                    this.item.organisations = this.item.organisations.filter(x => x != value)
                } else {
                    this.item.organisations.push(value)
                }
                this.shareKey++
            }
        },
        clickAction(type) {
            switch (type) {
                case 'create_folder':
                    this.createFolder()
                    break
                case 'upload_file':
                    this.addFiles()
                    break
                case 'download_file':
                    this.downloadDocument(this.tableMenuItem)
                    break
                case 'delete_file':
                    this.deleteDocument(this.tableMenuItem)
                    break
                case 'view_file':
                    this.previewItem(this.tableMenuItem)
                    break
                case 'share_file':
                    this.shareItem(this.tableMenuItem)
                    break
                case 'share_all':
                    this.shareAll()
                    break
                case 'delete_all':
                    this.deleteAll()
                    break
                case 'approve_file':
                    this.approveDocument(this.tableMenuItem, true)
                    break
                case 'reject_file':
                    this.approveDocument(this.tableMenuItem, false)
                    break
                case 'draft_document':
                    this.markDocument('isDraftDoc', this.tableMenuItem)
                    break
                case 'final_document':
                    this.markDocument('isFinalDoc', this.tableMenuItem)
                    break
                case 'add_classification':
                    this.addClassification(this.tableMenuItem)
                    break
            }
        },
        createFolder() {
            this.item = {
                type: 'folder',
                shipmentId: this.shipment.id,
                parentId: this.currentFolder ? this.currentFolder.id : null,
            }
            this.folderModal = true
        },
        deleteDocument(item) {
            this.$confirm(
                `Are you sure you want to delete "${item.name}"?`,
                "Confirm",
                {
                    center: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: "plain",
                }
            )
                .then(async () => {
                    // this.loading = true
                    await this.$API.updateShipmentDocument({
                        id: item.id,
                        isActive: false,
                        isDeleted: true,
                    });
                    let index = this.folderDocuments.findIndex(x => x.id == item.id)
                    this.folderDocuments.splice(index, 1);
                    this.documentKey++;
                    this.$message({
                        type: "success",
                        message: "Successfully deleted!",
                    });
                    this.previewModal = false
                    this.item = {}
                    this.tableMenu = false
                    this.tableMenuItem = null
                })
                .catch(() => {
                    //   this.$message({
                    //     type: "info",
                    //     message: "Delete canceled",
                    //   });
                });
        },
        doubleClickRow(e, item) {
            item = item.item
            if (item.settingType == 'folder') {
                // Open folder
                if (this.menuHistory.length == 1) {
                    this.originalFolder = item
                }
                this.menuHistory.push({
                    id: item.id,
                    name: item.name,
                    item: item,
                    count: this.getTotalItems,
                    shared: item.shipmentFolderShareds ? item.shipmentFolderShareds.length > 0 : false
                })
                this.currentFolder = item

            } else {
                this.item = item
                this.previewItem(this.item)
            }
        },
        documentClassifications(item) {
            let result = []
            if (item.shipmentDocumentClassifications) {
                let main = item.shipmentDocumentClassifications.filter(x => x.locationDocumentId).map(x => x.locationDocument)
                let supporting = item.shipmentDocumentClassifications.filter(x => x.supportingDocumentId).map(x => x.supportingDocument)
                result = [...main, ...supporting].filter(Boolean)
                return result
            }
            else return result
        },
        async downloadDocument(doc) {
            var hiddenElement = document.createElement("a");
            hiddenElement.href = doc.url;
            hiddenElement.target = "_blank";
            hiddenElement.download = doc.name;
            hiddenElement.click();
        },
        formatDate(date) {
            let result = null;
            if (date) {
                result = dateFormat(new Date(date), "dd-mm-yyyy HH:MM");
            }
            return result;
        },

        async getDocumentCount() {
            this.documentCount = await this.$API.getDocumentCount(this.shipment.id)
        },
        async getFolderDocuments() {
            if (this.selectedType == "My Documents") {
                this.loadingFolderDocuments = true
                this.folderDocuments = []
                let folderId = this.currentFolder ? this.currentFolder.id : null
                this.folderDocuments = await this.$API.listFolderDocuments(this.shipment.id, {
                    params: {
                        folderId: folderId
                    }
                })
                if (!this.currentFolder) {
                    this.menuHistory[0].shared = !this.menuHistory[0].shared ? this.folderDocuments.filter(x => x.shipmentFolderShareds.length > 0).length > 0 : this.menuHistory[0].shared
                } else {
                    this.menuHistory.at(-1).shared = !this.menuHistory.at(-1).shared ? this.folderDocuments.filter(x => x.shipmentFolderShareds.length > 0).length > 0 : this.menuHistory.at(-1).shared
                }
                this.loadingFolderDocuments = false
            } else if (this.selectedType == 'Draft Documents') {
                this.loadingFolderDocuments = true
                this.loadingSharedDocuments = true

                this.folderDocuments = []
                this.folderDocuments = await this.$API.listMyDraftDocuments(this.shipment.id, {})
                if (!this.currentFolder) {
                    this.menuHistory[0].shared = !this.menuHistory[0].shared ? this.folderDocuments.filter(x => x.shipmentFolderShareds.length > 0).length > 0 : this.menuHistory[0].shared
                } else {
                    this.menuHistory.at(-1).shared = !this.menuHistory.at(-1).shared ? this.folderDocuments.filter(x => x.shipmentFolderShareds.length > 0).length > 0 : this.menuHistory.at(-1).shared
                }
                this.sharedDocuments = await this.$API.getSharedDraftDocuments(this.shipment.id, {})

                this.loadingFolderDocuments = false
                this.loadingSharedDocuments = false
            } else if (this.selectedType == 'Final Documents') {
                this.loadingFolderDocuments = true
                this.loadingSharedDocuments = true
                this.folderDocuments = []
                this.folderDocuments = await this.$API.listMyFinalDocuments(this.shipment.id, {})
                if (!this.currentFolder) {
                    this.menuHistory[0].shared = !this.menuHistory[0].shared ? this.folderDocuments.filter(x => x.shipmentFolderShareds.length > 0).length > 0 : this.menuHistory[0].shared
                } else {
                    this.menuHistory.at(-1).shared = !this.menuHistory.at(-1).shared ? this.folderDocuments.filter(x => x.shipmentFolderShareds.length > 0).length > 0 : this.menuHistory.at(-1).shared
                }
                this.sharedDocuments = await this.$API.getSharedFinalDocuments(this.shipment.id, {})
                this.loadingFolderDocuments = false
                this.loadingSharedDocuments = false
            }

        },
        getFriendlyShareType(type) {
            let find = this.shareTypes.find(x => x.value == type)
            return find ? find.name : null
        },
        getFriendlyShareTypeIcon(type) {
            let find = this.shareTypes.find(x => x.value == type)
            return find ? find.icon : null
        },
        getFriendlyShareTypeColour(type) {
            let find = this.shareTypes.find(x => x.value == type)
            return find ? find.color : null
        },
        getIcon(name) {
            let type = name.split(".");
            let result = null;
            if (type.length > 0) {
                result = type.at(-1).toLowerCase();
            }
            if (result && result.toLowerCase() == "xlsx") {
                result = "xls";
            }
            return `/icons/${result}.svg`;
        },
        async getFolders() {
            if (this.selectedType == "My Documents") {
                this.loadingFolders = true

                this.myFolders = []
                this.myFolders = await this.$API.getShipmentFolders(this.shipment.id, {
                    params: {
                        folderId: this.currentFolder ? this.currentFolder.id : null
                    }
                })
                if (!this.currentFolder) {
                    this.menuHistory[0].shared = !this.menuHistory[0].shared ? this.myFolders.filter(x => x.shipmentFolderShareds.length > 0).length > 0 : this.menuHistory[0].shared
                } else {
                    this.menuHistory.at(-1).shared = !this.menuHistory.at(-1).shared ? this.myFolders.filter(x => x.shipmentFolderShareds.length > 0).length > 0 : this.menuHistory.at(-1).shared
                }
                this.loadingFolders = false

            } else if (this.selectedType == "Shared Documents") {
                this.loadingSharedFolders = true
                this.sharedFolders = []
                this.sharedFolders = await this.$API.getSharedShipmentFolders(this.shipment.id, {
                    params: {
                        originalReference: this.originalFolder ? this.originalFolder.reference : null,
                        folderId: this.currentFolder ? this.currentFolder.reference : null
                    }
                })
                this.loadingSharedFolders = false
            }

        },
        async getSuggestedDocuments() {
            this.loadingSuggestions = true;
            this.suggestions = await this.$API.getShipmentLinkedDocuments(
                this.shipment.id
            );
            this.loadingSuggestions = false;
            if (this.suggestions.length > 0) {
                let find = this.selectableTypes.find(x => x.value == 'Suggested Documents')
                if (!find) {
                    this.selectableTypes.push(
                        { name: 'Suggested Documents', value: 'Suggested Documents', icon: 'tips_and_updates', color: 'teal' },
                    )
                }

            } else {
                this.selectableTypes = this.selectableTypes.filter(x => x.value != 'Suggested Documents')
            }
        },
        async getSupportingDocs() {
            this.supportingDocs = await this.$API.getCountrySupportingDocuments(this.shipment.originCountryId)
            this.supportingDocs.sort((a, b) => a.name.localeCompare(b.name))
        },
        linkedContainers(containers) {
            return containers ? this.bookings.filter(x => containers.map(y => y.bookingContainerId).includes(x.id)):[]
        },
        async markDocument(type, document) {
            document[type] = !document[type]
            // let findMatch = this.folderDocuments.find(x => x.id == document.id)
            // findMatch[type] = document[type]
            this.markKey++
            let obj = {
                id: document.id,
                [type]: document[type]
            }
            if (type == 'isDraftDoc') {
                obj.isDraftDoc = document[type]
                obj.draftCreatedById = this.$store.state.user.id
                obj.draftCreatedDate = new Date()
            } else if (type == 'isFinalDoc') {
                obj.isFinalDoc = document[type]
                obj.finalCreatedById = this.$store.state.user.id
                obj.finalCreatedDate = new Date()
            }
            await this.$API.updateShipmentDocument(obj)
            this.$message.success("Successfully updated document!")
            this.getDocumentCount()
        },
        previewItem(item) {
            this.item = item
            if (this.item.type && (this.item.type.includes('image/') || this.item.type.includes('application/pdf'))) {
                this.imageLoading = true
            }
            this.previewModal = true
        },
        removeFromShareList(item) {
            let findIndex = this.selectedFiles.findIndex(x => x.id == item.id)
            this.selectedFiles.splice(findIndex, 1)
            if (this.selectedFiles.length == 0) {
                this.shareDialog = false
            }
        },
        async removeOrganisationFromShareList(item) {
            item.loading = true
            await this.$API.updateShareShipmentDocuments({
                id: item.id,
                isActive: false,
                isDeleted: true
            })
            item.loading = false
            this.$message.success('Successfully removed from share list!')
            let findIndex = this.item.shipmentFolderShareds.find(x => x.id == item.id)
            this.item.shipmentFolderShareds.splice(findIndex, 1)
        },
        async removeFromDrafts(item) {
            item.loading = true
            await this.$API.updateShipmentDocument({
                id: item.id,
                isDraftDoc: false,
                draftCreatedById: null,
                draftCreatedDate: null
            })
            item.loading = false
            this.$message.success('Successfully removed from draft documents!')
            let findIndex = this.folderDocuments.findIndex(x => x.id == item.id)
            this.folderDocuments.splice(findIndex, 1)
        },
        async removeFromFinal(item) {
            item.loading = true
            await this.$API.updateShipmentDocument({
                id: item.id,
                isFinalDoc: false,
                finalCreatedById: null,
                finalCreatedDate: null
            })
            item.loading = false
            this.$message.success('Successfully removed from final documents!')
            let findIndex = this.folderDocuments.findIndex(x => x.id == item.id)
            this.folderDocuments.splice(findIndex, 1)
        },
        async saveSuggestedDocuments() {
            this.savingSuggestedDocuments = true
            let suggestions = [...this.selectedSuggestions]
            for (let i = 0; i < suggestions.length; i++) {
                let item = suggestions[i]
                console.log(item)
                item.suggestion = true;
                let mainDocuments =
                    item.shipmentDocumentClassifications
                        .filter((x) => x.locationDocumentId)
                        .map((x) => ({ locationDocumentId: x.locationDocumentId }));
                let supporting =
                    item.shipmentDocumentClassifications
                        .filter((x) => x.supportingDocumentId)
                        .map((x) => ({ supportingDocumentId: x.supportingDocumentId }));

                item.shipmentDocumentClassifications = [...mainDocuments, ...supporting]
                item.shipmentContainerDocuments =
                    item.shipmentContainerDocuments.map(
                        (x) => ({ bookingContainerId: x.bookingContainerId })
                    );
                if (item.isFinancialDocument) {
                    item.shipmentDocumentMetadata =
                        await this.$API.getShipmentDocumentMetadata(item.id);
                    item.shipmentDocumentMetadata =
                        item.shipmentDocumentMetadata.filter(
                            (x) => x.type != "OTHER"
                        );
                }
                delete item.id;
                item.shipmentId = this.shipment.id;
                if (item.shipmentDocumentClassifications) {
                    item.shipmentDocumentClassifications.forEach(x => {
                        delete x.id
                        delete x.shipmentDocumentId
                    })
                } else {
                    item.shipmentDocumentClassifications = []
                }

                if (item.shipmentDocumentMetadata) {
                    item.shipmentDocumentMetadata.forEach(x => {
                        delete x.id
                        delete x.shipmentDocumentId
                    })
                } else {
                    item.shipmentDocumentMetadata = []
                }

                item = await this.$API.copyShipmentDocument(item);
            }
            this.selectedSuggestions = []
            this.getSuggestedDocuments()
            this.savingSuggestedDocuments = false
        },
        selectAllOrganisations() {
            this.item.organisations = this.availableParties.map(x => x.value)
            this.shareKey++
        },
        shareAll() {
            // console.log(this.selectedFileList)
            this.selectedFiles = this.selectedFileList
            this.item = {
                type: 'view_only',
                organisations: []
            }
            this.shareDialog = true
        },
        deleteAll() {
            this.$confirm(
                `Are you sure you want to delete "${this.selectedFileList.length} items(s)"?`,
                "Confirm",
                {
                    center: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: "plain",
                }
            )
                .then(async () => {
                    // this.loading = true
                    await this.$API.updateBulkShipmentDocument({
                        folders: this.selectedFileList.filter(x => x.settingType == 'folder').map(x => ({ id: x.id, isDeleted: true, isActive: false })),
                        documents: this.selectedFileList.filter(x => x.settingType == 'file').map(x => ({ id: x.id, isDeleted: true, isActive: false })),
                    });
                    this.$message({
                        type: "success",
                        message: "Successfully deleted!",
                    });
                    this.getFolderDocuments()
                    this.getFolders()
                })
                .catch(() => {
                    //   this.$message({
                    //     type: "info",
                    //     message: "Delete canceled",
                    //   });
                });
        },
        async saveContainerDocuments() {
            this.savingContainerDocuments = true
            await this.$API.updateShipmentContainerDocuments({
                id: this.linkedContainersDocument.id,
                shipmentContainers: this.selectedContainers,
            });
            this.getFolderDocuments()
            this.getFolders()
            //     let existingSupportingDocs = this.linkedContainersDocument.shipmentContainerDocuments
            // .filter((x) => x.bookingContainerId)
            // .map((x) => x.bookingContainerId);
            //     let add = this.selectedContainers.filter(
            //         (x) => !existingSupportingDocs.includes(x)
            //     );
            //     let remove = existingSupportingDocs.filter(
            //         (x) => !this.selectedContainers.includes(x)
            //     );
            //     for (let i = 0; i < add.length; i++) {
            //         let classification = {
            //             shipmentDocumentId: this.linkedContainersDocument.id,
            //             bookingContainerId: add[i],
            //         };
            //         let result = await this.$API.createShipmentContainerDocument(
            //             classification
            //         );
            //         this.linkedContainersDocument.shipmentContainerDocuments.push(result);
            //     }
            //     for (let i = 0; i < remove.length; i++) {
            //         let classification = this.linkedContainersDocument.shipmentContainerDocuments.find(
            //             (x) => x.bookingContainerId == remove[i]
            //         );
            //         // await this.$API.updateShipmentContainerDocuments({
            //         //     id: classification.id,
            //         //     isActive: false,
            //         //     isDeleted: true,
            //         // });
            //         let index = this.linkedContainersDocument.shipmentContainerDocuments.findIndex(
            //             (x) => x.id == classification.id
            //         );
            //         this.linkedContainersDocument.shipmentContainerDocuments.splice(index, 1);
            //     }
            this.containerDocumentKey++
            this.linkedContainersModal = false
            this.$message.success('Successfully updated linked containers!')
            this.savingContainerDocuments = false
        },
        selectContainer(id) {
            let findIndex = this.selectedContainers.indexOf(id)
            if (findIndex == -1) {
                this.selectedContainers.push(id)
            } else {
                this.selectedContainers.splice(findIndex, 1)
            }
        },
        shareItem(item) {
            this.selectedFiles = [item]
            this.item = {
                type: 'view_only',
                organisations: []
            }
            this.shareDialog = true
        },
        async saveClassifications() {
            this.savingClassifications = true
            let existingClassifications = this.documentItem.shipmentDocumentClassifications
                .filter((x) => x.locationDocumentId)
                .map((x) => x.locationDocumentId);

            let add = this.selectedMainDocuments.filter(
                (x) => !existingClassifications.includes(x)
            );
            let remove = existingClassifications.filter(
                (x) => !this.selectedMainDocuments.includes(x)
            );
            for (let i = 0; i < add.length; i++) {
                let classification = {
                    shipmentDocumentId: this.documentItem.id,
                    locationDocumentId: add[i],
                };
                let result = await this.$API.createShipmentDocumentClassification(
                    classification
                );
                this.documentItem.shipmentDocumentClassifications.push(result);
            }
            for (let i = 0; i < remove.length; i++) {
                let classification = this.documentItem.shipmentDocumentClassifications.find(
                    (x) => x.locationDocumentId == remove[i]
                );
                await this.$API.updateShipmentDocumentClassification({
                    id: classification.id,
                    isActive: false,
                    isDeleted: true
                });
                let index = this.documentItem.shipmentDocumentClassifications.findIndex(
                    (x) => x.id == classification.id
                );
                this.documentItem.shipmentDocumentClassifications.splice(index, 1);
            }

            let existingSupportingClassifications = this.documentItem.shipmentDocumentClassifications
                .filter((x) => x.supportingDocumentId)
                .map((x) => x.supportingDocumentId);

            let addSupporting = this.selectedSupportingDocuments.filter(
                (x) => !existingSupportingClassifications.includes(x)
            );
            let removeSupporting = existingSupportingClassifications.filter(
                (x) => !this.selectedSupportingDocuments.includes(x)
            );
            for (let i = 0; i < addSupporting.length; i++) {
                let classification = {
                    shipmentDocumentId: this.documentItem.id,
                    supportingDocumentId: addSupporting[i],
                };
                let result = await this.$API.createShipmentDocumentClassification(
                    classification
                );
                this.documentItem.shipmentDocumentClassifications.push(result);
            }
            for (let i = 0; i < removeSupporting.length; i++) {
                let classification = this.documentItem.shipmentDocumentClassifications.find(
                    (x) => x.supportingDocumentId == removeSupporting[i]
                );
                await this.$API.updateShipmentDocumentClassification({
                    id: classification.id,
                    isActive: false,
                    isDeleted: true
                });
                let index = this.documentItem.shipmentDocumentClassifications.findIndex(
                    (x) => x.id == classification.id
                );
                this.documentItem.shipmentDocumentClassifications.splice(index, 1);
            }
            this.$message.success("Successfully updated classifications!");
            this.classificationModal = false
            this.documentItem = null
            this.selectedMainDocuments = []
            this.selectedSupportingDocuments = []
            this.savingClassifications = false
        },
        async saveFolder() {
            this.savingFolder = true
            let result = await this.$API.createShipmentFolders(this.item)
            if (result) {
                result.children = 0
                result.shipmentFolderShareds = []
                this.myFolders.push(result)
                this.folderKey++
            }
            this.savingFolder = false
            this.folderModal = false
        },
        showMenuOptions(e) {

        },
        setMenuPosition(item, index) {
            this.menuHistory = this.menuHistory.slice(0, index + 1)
            this.currentFolder = item
        },
        async submitFiles() {
            let pending = this.fileList.filter((x) => !x.uploaded);
            for (let i = 0; i < pending.length; i++) {
                pending[i].loading = true
                let url = await URL.createObjectURL(pending[i].file);
                pending[i].fileIndex = this.lastIndex
                pending[i].uploaded = true
                this.toDataUrl(url, (data) => {
                    let obj = {
                        name: pending[i].name,
                        data: data,
                        type: pending[i].type,
                        size: pending[i].size,
                        fileIndex: pending[i].fileIndex,
                        shipmentId: this.shipment.id,
                        shipmentFolderId: this.currentFolder ? this.currentFolder.id : null,
                        uploaded: true
                    };
                    this.submitUpload(obj);
                });
                this.lastIndex++;
            }
        },
        async submitShare() {
            this.submittingShare = true
            let obj = {
                type: this.item.type,
                shipmentId: this.shipment.id,
                organisations: this.item.organisations,
                folders: this.selectedFiles.filter(x => x.settingType == 'folder').map(x => x.id),
                documents: this.selectedFiles.filter(x => x.settingType == 'file').map(x => x.id),
            }
            let result = await this.$API.shareShipmentDocuments(obj)
            this.$message.success('Successfully shared ' + this.selectedFiles.length + ' items with ' + this.item.organisations.length + ' organisations!')

            this.shareDialog = false
            this.getFolders()
            this.getFolderDocuments()
            this.item = {}
            this.selectedFiles = []
            this.selectedFileList = []
            this.submittingShare = false
        },
        async submitItemShare() {
            this.submittingShare = true
            let obj = {
                type: this.item.shareType,
                shipmentId: this.shipment.id,
                organisations: this.shareList,
                folders: this.item.settingType == 'folder' ? [this.item.id] : [],
                documents: this.item.settingType == 'file' ? [this.item.id] : []
            }
            let result = await this.$API.shareShipmentDocuments(obj)
            this.$message.success('Successfully shared ' + this.item.name + ' with ' + this.shareList.length + ' organisations!')
            this.sharedItemModal = false
            this.item = {}
            this.shareList = []
            this.getFolders()
            this.getFolderDocuments()
            this.submittingShare = false
        },
        async submitUpload(obj) {
            console.log("ENV", process.env)
            let Api = axios.create({
                // baseURL: "http://localhost:3000",
                // baseURL: "https://api.loglive.io",
                baseURL: process.env.NODE_ENV == 'production' ? 'https://api.loglive.ai' : process.env.NODE_ENV == 'staging' ? 'https://api.loglive.ai' : 'http://localhost:3000',
                headers: {
                    Authorization: `${this.$store.state.token}`,
                    apikey: `${this.$store.state.orgToken}`,
                },
            });
            let result = await Api.post("/upload/document", obj, {
                onUploadProgress: (progressEvent) => {
                    let find = this.fileList.findIndex(x => x.fileIndex == obj.fileIndex)
                    if (find > -1) {
                        this.fileList[find].progress = Math.ceil(
                            (progressEvent.loaded / progressEvent.total) * 100
                        );
                    }
                }
            });
            result = result.data;
            this.folderDocuments.push(result)
            this.$emit('getShipmentDocuments')
            this.documentKey++;
        },
        toDataUrl(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.send();
        },
        viewSharedItem(item) {
            this.item = item
            this.item.shareType = 'view_only'
            this.sharedItemModal = true
        },
        viewTableMenu(e, item) {
            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY
            this.tableMenuItem = item.item
            this.$nextTick(() => {
                this.tableMenu = true
            })
        },

        refresh() {
            this.getFolderDocuments()
            this.getFolders()
        },
        uniqueClassifications(item) {
            if (item.shipmentDocumentClassifications) {
                let uniqueDocs = [...new Set(item.shipmentDocumentClassifications.filter(x => x.locationDocumentId).map(x => x.locationDocumentId))]
                let uniqueSupporting = [...new Set(item.shipmentDocumentClassifications.filter(x => x.supportingDocumentId).map(x => x.locationsupportingDocumentIdDocumentId))]
                return uniqueDocs.length + uniqueSupporting.length
            }
            else return 0
        },
        viewLinkedContainers(document) {
            this.linkedContainersDocument = document
            this.selectedContainers = document.shipmentContainerDocuments ? document.shipmentContainerDocuments.map(x => x.bookingContainerId):[]
            this.linkedContainersModal = true
        }
    }
}
</script>