<template>
    <div>
        <v-card flat class="mt-0 pt-0">
            <v-card-text class="mt-0 pt-0">
                <v-row justify="end">
                    <v-col cols="12" sm="4">
                        <el-input ref="searchBar" suffix-icon="el-icon-search" v-model="search" clearable
                            placeholder="Search">
                        </el-input>
                    </v-col>
                </v-row>
                <v-data-table :items="bookings" :headers="headers" :search="search" disable-pagination hide-default-footer
                    @click:row="viewContainer" style="cursor: pointer" height="60vh" fixed-header>
                    <template v-slot:[`item.nettWeight`]="{ item }">
                        <span>{{ containerNettWeight(item) }}</span>
                    </template>

                    <template v-slot:[`item.grossWeight`]="{ item }">
                        <span>{{ containerGrossWeight(item) }}</span>
                    </template>
                    <template v-slot:[`item.booking.state`]="{ item }">
                        <v-chip small outlined :color="getIconColor(item.booking.state)">
                            {{ item.booking.state }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <v-tooltip top
                            v-if="item.status && (scope.type == 'paid' || scope.type == 'trial' || scope.type == 'free')">
                            <template v-slot:activator="{ on }">
                                <span v-on="on">{{ item.status }}</span>
                            </template>
                            <span>Last Update: {{ formatDate(item.statusDate) }}</span>
                        </v-tooltip>
                        <v-tooltip top v-else-if="item.status && scope.type == 'free'">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" color="amber">lock</v-icon>
                            </template>
                            <span>This feature is available in the paid subscription</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.statusLocode`]="{ item }">
                        <v-tooltip top
                            v-if="item.statusLocode && (scope.type == 'paid' || scope.type == 'trial' || scope.type == 'free')">
                            <template v-slot:activator="{ on }">
                                <v-chip v-on="on" pill small>
                                    <v-avatar size="32" left>
                                        <v-img contain
                                            :src="`https://cdn.loglive.io/flags/4x3/${item.statusLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                    </v-avatar>
                                    {{ item.statusLocode }}
                                </v-chip>

                            </template>
                            <span>{{ item.statusLocation }} {{ formatDate(item.statusDate) }}</span>
                        </v-tooltip>
                        <v-tooltip top v-else-if="item.statusLocode && scope.type == 'free'">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" color="amber">lock</v-icon>
                            </template>
                            <span>This feature is available in the paid subscription</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:[`item.products`]="{ item }">
                        <ul>
                            <li v-for="(product) in containerProductSummary(item)" :key="product.id"
                                style="font-size: 12px">
                                {{ product.product.name }} {{ product.variety ? ': ' + product.variety.name : ''
                                }} <span v-if="product.varietyPercentage" class="ml-1" style="color: grey">({{
    product.varietyPercentage
}}%)</span>

                            </li>
                        </ul>
                    </template>
                    <template v-slot:[`item.noPallets`]="{ item }">
                        {{ calculateUniquePallets(item.containerPallets) }}
                    </template>
                    <template v-slot:[`item.noCartons`]="{ item }">
                        {{ calculateCartons(item.containerPallets) }}
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="containerModal" fullscreen>
            <v-card v-if="item && item.id">
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>
                        <v-btn icon @click="containerModal = false, item = {}"
                            class="mr-1"><v-icon>arrow_back</v-icon></v-btn>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text @click="containerModal = false, item = {}">X</v-btn>
                </v-toolbar>
                <v-card-text>

                    <v-row>
                        <v-col cols="12" sm="3" md="2">
                            <v-card style="background-color: var(--v-component-base) !important" height="88vh">
                                <v-card-text>
                                    <v-list dense subheader style="max-height: 85vh;overflow-y: auto">
                                        <v-subheader style="font-size: 18px"><v-icon color="primary" class="mr-1"
                                                small>widgets</v-icon> Details</v-subheader>
                                        <v-divider></v-divider>
                                        <v-list-item v-if="item.containerNo">
                                            <v-list-item-action>
                                                <v-icon color="secondary" small>tag</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.containerNo }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{ !shipment.breakBulkShipment ? 'Container No.' : 'Deck No.' }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-if="item.ctoNo">
                                            <v-list-item-action>
                                                <v-icon color="secondary" small>description</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.ctoNo }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    CTO Number
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-if="item.sealNo">
                                            <v-list-item-action>
                                                <v-icon color="secondary" small>label</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.sealNo }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    Seal Number
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-action>
                                                <v-icon color="secondary" small>scale</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ containerGrossWeight(item) }} KGS
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    Gross Weight
                                                </v-list-item-subtitle>
                                            </v-list-item-content>

                                        </v-list-item>
                                        <v-list-item v-if="item.containerNo">
                                            <v-list-item-action>
                                                <v-icon color="secondary" small>scale</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ containerNettWeight(item) }} KGS
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    Nett Weight
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider>
                                        </v-divider>
                                        <v-subheader style="font-size: 18px"><v-icon class="mr-1" color="primary"
                                                small>category</v-icon> Commodities</v-subheader>
                                        <v-divider></v-divider>
                                        <v-list-item v-for="(product, index) in containerProducts" :key="product.productId">
                                            <v-list-item-action>
                                                <v-chip>{{ index + 1 }}</v-chip>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ product.product.name }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{ product.noPallets }} Pallets
                                                </v-list-item-subtitle>
                                                <div v-if="binsAndCartons">
                                                    <v-list-item-subtitle style="font-size: 12px">
                                                        <v-icon small class="mr-1">widgets</v-icon> {{
                                                            calculateProductBins(product.productId) }} BINS
                                                    </v-list-item-subtitle>
                                                    <v-list-item-subtitle style="font-size: 12px">
                                                        <v-icon small class="mr-1">widgets</v-icon> {{
                                                            calculateProductCartons(product.productId) }}
                                                        CARTONS
                                                    </v-list-item-subtitle>
                                                </div>
                                                <div v-else>
                                                    <v-list-item-subtitle style="font-size: 12px">
                                                        <v-icon small class="mr-1">widgets</v-icon> {{
                                                            getPackType() == 'BIN' ? calculateProductBins(product.productId) :
                                                            calculateProductCartons(product.productId) }}
                                                        {{ getPackType() + 'S' }}
                                                    </v-list-item-subtitle>
                                                </div>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider>
                                        </v-divider>
                                        <v-subheader style="font-size: 18px"><v-icon class="mr-1" color="primary"
                                                small>thermostat</v-icon> Monitoring Device(s)</v-subheader>
                                        <v-divider></v-divider>
                                        <v-progress-linear color="primary" indeterminate
                                            v-if="loadingSensor"></v-progress-linear>
                                        <v-list-item v-for="device in item.telemetry" :key="device.id">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ device.serial }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    <v-avatar size="16" class="mr-1" v-if="device.provider == 'SensiTech'">
                                                        <img contain src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBgkIBwEKCgkBDRYDDQEBARsIFRAWIB0iIiAdExMkKDQsJCYxJx8fLTEtMTU3Ojo6Iys/RD84QzQ5OisBCgoKDQwBGg8PGjclHyU3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Kzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3N//AABEIABAAEAMBIgACEQEDEQH/xAAWAAADAAAAAAAAAAAAAAAAAAAEBQb/xAAnEAAABQMCBQUAAAAAAAAAAAABAgMEBgUHEQBBFCEyUWESFRYjMf/EABQBAQAAAAAAAAAAAAAAAAAAAAT/xAAYEQACAwAAAAAAAAAAAAAAAAABAgAGEf/aAAwDAQACEQMRAD8Av6zWFDleuBnSyNPsI4CFhCbQSUEDuFsh1Kj0FARAOXnOmkdVVXSEVIy8bmh5uCPCbtXSGoAbsJFd/wB0J7Q8IvUGKUn9Le+6/wA3JcyTwIrwuRx60lScsh28b8tGx+hlopnhizApguscHgW3t7AeESSwGPrTyOM76Q5QVnBDIGNg0z//2Q==
                    ">
                                                    </v-avatar>
                                                    {{ device.provider }}
                                                </v-list-item-subtitle>
                                                <!-- <v-list-item-subtitle v-if="device.lastLocation">

                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <div v-on="on">
                                                                <v-icon small class="mr-1">location_on</v-icon> {{
                                                                    formatDate(device.lastLocationDate) }}
                                                            </div>
                                                        </template>
                                                        <span style="font-size: 12px">Last Updated: {{
                                                            formatDate(device.lastLocationDate) }}</span>
                                                    </v-tooltip>
                                                </v-list-item-subtitle> -->
                                                <!-- <v-list-item-subtitle v-if="device.lastTemperature">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <div v-on="on">
                                                                <v-icon small class="mr-1"
                                                                    :color="device.lastTemperature > 0 ? 'orange' : 'blue'">thermostat</v-icon>
                                                                {{ device.lastTemperature.toFixed(2) }} °C

                                                            </div>
                                                        </template>
                                                        <span style="font-size: 12px">Last Updated: {{
                                                            formatDate(device.lastTemperatureDate) }}</span>
                                                    </v-tooltip>
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="device.lastLight !== undefined">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <div v-on="on">
                                                                <v-icon small class="mr-1"
                                                                    color="yellow">light_mode</v-icon> {{ device.lastLight
                                                                    }} Lux
                                                            </div>
                                                        </template>
                                                        <span style="font-size: 12px">Last Updated: {{
                                                            formatDate(device.lastLightDate) }}</span>
                                                    </v-tooltip>

                                                </v-list-item-subtitle> -->
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-if="!loadingSensor && item.telemetry.length == 0">
                                            <v-list-item-content class="text-center">
                                                <span style="color: grey; font-size: 12px">No devices found</span>
                                            </v-list-item-content>
                                        </v-list-item>

                                    </v-list>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="9" md="10">
                            <v-tabs v-model="containerTab" :key="telemetryKey">
                                <v-tabs-slider color="primary"></v-tabs-slider>
                                <v-tab href="#summary">
                                    Summary
                                </v-tab>
                                <v-tab href="#telemetry" v-if="item && item.telemetry && item.telemetry.length > 0">
                                    Telemetry
                                </v-tab>
                                <v-tab href="#contents">
                                    Contents
                                </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="containerTab" style="background-color: transparent">
                                <v-tab-item value="summary" style="background-color: transparent">
                                    <v-card flat style="border-radius: 0px">
                                        <v-card-text>
                                            <v-subheader style="font-size: 16px"><v-icon color="secondary"
                                                    class="mr-2">category</v-icon> Product Breakdown</v-subheader>
                                            <v-divider></v-divider>
                                            <ProductBreakdownTable :headers="containerSummaryHeaders" :items="summaryItems"
                                                :tableKey="tableKey" :loading="loadingBreakdown" :height="'100%'" />

                                            <div v-if="events && events.length > 0">
                                                <v-divider></v-divider>
                                                <v-subheader style="font-size: 16px"><v-icon color="secondary"
                                                        class="mr-2">category</v-icon> Container Tracking </v-subheader>
                                                <v-divider></v-divider>
                                                <!-- <DCSAContainerTracking class="mt-2" :events="events" :vertical="false" /> -->
                                                <DCSAContainerTracking class="mt-1 pt-1" v-if="eventType == 'DCSA'"
                                                    :events="events" :vertical="false" />
                                                <ContainerMilestone class="mt-1 pt-1" v-else :events="events"
                                                    :vertical="false" />
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item value="telemetry">
                                    <v-card flat style="border-radius: 0px">
                                        <v-card-text>
                                            <ContainerTelemetry :data="item.telemetry" />
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item value="contents">
                                    <v-card flat style="border-radius: 0px">
                                        <v-card-text>
                                            <v-row justify="end">
                                                <v-col cols="12" sm="4">
                                                    <el-input ref="searchBar" suffix-icon="el-icon-search"
                                                        v-model="searchPallets" clearable placeholder="Search">
                                                    </el-input>
                                                </v-col>
                                            </v-row>
                                            <v-data-table :headers="palletHeaders" :items="item.containerPallets"
                                                :search="searchPallets" dense fixed-header height="76vh" disable-pagination
                                                hide-default-footer>
                                                <template v-slot:[`item.line`]="{ item }">
                                                    {{ uniquePallets.indexOf(item.sscc) + 1 }}
                                                </template>
                                            </v-data-table>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-col>
                    </v-row>



                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="trackerModal" persistent width="600px" :fullscreen="$vuetify.breakpoint.mobile">
            <v-card v-if="trackerModal">
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>
                        Container Tracking: {{ container.containerNo }} <span v-if="container.ctoNo"> - {{ container.ctoNo
                        }}</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text @click="trackerModal = false, events = []">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <DCSAContainerTracking :events="events" />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios'
import ContainerTelemetry from '../Telemetry/ContainerTelemetry.vue'
import ContainerMilestone from '../ContainerTracking/ContainerMilestone.vue'

import dateFormat from 'dateformat';
// import DynamicSmoothLineChart from '../Charts/DynamicSmoothLineChart.vue'
import DCSAContainerTracking from '../ContainerTracking/DCSA.vue'
// import PieChart from '../Charts/PieChart.vue';
import ProductBreakdownTable from '../SystemComponents/ProductBreakdownTable.vue'
export default {
    props: ["shipment", "bookings", "documents", "scope", "shipmentProducts"],
    components: {
        ContainerTelemetry,
        ContainerMilestone,
        // DynamicSmoothLineChart,
        DCSAContainerTracking,
        ProductBreakdownTable
        // PieChart,
    },
    data: () => ({
        container: null,
        containerSummaryModal: false,
        containerModal: false,
        containerTab: 'summary',
        chartKey: 2000,
        events: [],
        eventType: null,
        item: null,
        loadingSensor: false,
        searchPallets: null,
        trackerModal: false,
        containerSummaryHeaders: [
            {
                text: "Product",
                value: "productName",
                align: "center",
            },
            {
                text: "Variety",
                value: "varietyName",
                align: "center",
            },
            {
                text: "Grade/Class",
                value: "gradeClassCode",
                align: "center",
            },
            {
                text: "Pack Code",
                value: "packCode",
                align: "center",
            },
            {
                text: "Count/Size",
                value: "countSize",
                align: "center",
            },
            {
                text: "No. Cartons",
                value: "noCartons",
                align: "center",
            },
            {
                text: "Percentage",
                value: "percentage",
                align: "center",
            },
        ],
        headers: [
            // {
            //     text: "Action",
            //     align: "center",
            //     width: "80px",
            //     value: "action",
            //     sortable: false,
            // },
            {
                text: "Container No",
                value: "containerNo",
                align: "center",
            },
            {
                text: "Status",
                value: "status",
                align: "center",
            },
            {
                text: "Client Ref",
                value: "customerContainerRef",
                align: "center",
            },
            {
                text: "Carrier Ref",
                align: "center",
                value: "booking.carrierReferenceNumber",
            },

            {
                text: "CTO No",
                value: "ctoNo",
                align: "center",
            },
            {
                text: "Seal No",
                value: "sealNo",
                align: "center",
            },

            {
                text: "Products",
                value: "products",
                align: "left",
            },

            {
                text: "Nett(KG)",
                value: "nettWeight",
                align: "center",
            },
            {
                text: "Gross(KG)",
                value: "grossWeight",
                align: "center",
            },
            {
                text: "Pallets",
                value: "noPallets",
                align: "center",
            },
            {
                text: "Cartons",
                value: "noCartons",
                align: "center",
            },
            {
                text: "Telemetry Device",
                value: "tempSerialNo",
                align: "center",
            },
        ],
        palletHeaders: [
            {
                text: "Line",
                value: "line",
                sortable: false,
                width: "5px",
                align: "center",
            },
            {
                text: "SSCC / Pallet ID",
                value: "sscc",
                sortable: false,
                align: "center",
            },
            {
                text: "Producer/ PUC(s)",
                value: "farm",
                sortable: false,
                align: "center",
            },
            {
                text: "Orchard",
                value: "orchard",
                sortable: false,
                align: "center",
            },
            {
                text: "Phyto Data",
                value: "phytoReference",
                sortable: false,
                align: "center",
            },
            {
                text: "PHC",
                value: "packHouse",
                sortable: false,
                align: "center",
            },
            {
                text: "Production Area",
                value: "productionArea",
                sortable: false,
                align: "center",
            },
            {
                text: "Commodity",
                value: "commodity",
                sortable: false,
                align: "center",
            },
            {
                text: "Variety",
                value: "variety",
                sortable: false,
                align: "center",
            },
            {
                text: "Flesh Colour",
                value: "fleshColour",
                sortable: false,
                align: "center",
            },
            {
                text: "Class/ Grade",
                value: "gradeClassCode",
                width: "20px",

                sortable: false,
                align: "center",
            },
            {
                text: "Count",
                value: "countSize",
                width: "20px",
                sortable: false,
                align: "center",
            },
            {
                text: "Pack Type",
                value: "packType",
                sortable: false,
                width: "10px",
                align: "right",
            },
            {
                text: "Quantity",
                value: "noCartons",
                sortable: false,
                width: "10px",
                align: "right",
            },
            {
                text: "Nett Weight (KG)",
                value: "nettWeight",
                sortable: false,
                align: "right",
            },
            {
                text: "Gross Weight (KG)",
                value: "grossWeight",
                sortable: false,
                align: "right",
            },

            {
                text: "PPECB Inspection Date",
                value: "inspectionDate",
                width: "100px",
                sortable: false,
                align: "center",
            },
            {
                text: "Stuff Date",
                value: "stuffDate",
                width: "100px",
                sortable: false,
                align: "center",
            },
            {
                text: "Manifest No.",
                value: "inspectionManifestNo",
                sortable: false,
                align: "center",
            },
            {
                text: "Inspection Point",
                value: "inspectionPoint",
                sortable: false,
                align: "center",
            },
        ],
        summaryHeaders: [
            {
                text: "Line",
                value: "line",
                sortable: false,
                width: "5px",
                align: "center",
            },
            {
                text: "SSCC / Pallet ID",
                value: "sscc",
                sortable: false,
                align: "center",
            },
            {
                text: "Producer/ PUC(s)",
                value: "farm",
                sortable: false,
                align: "center",
            }
        ],
        loadingBreakdown: false,
        search: null,
        sensorData: [],
        summaryItems: [],
        tableKey: 4000,
        telemetryKey: 7000
    }),
    computed: {
        binsAndCartons() {
            let result = false
            if (this.item && this.item.containerPallets) {
                let mapped = this.item.containerPallets.map(x => x.packType)
                if (mapped.includes('BIN') && mapped.includes('CARTON')) {
                    result = true
                }
            }
            return result
        },
        containerProducts() {
            let result = []
            if (this.item && this.item.containerPallets) {
                let uniqueProducts = [...new Set(this.item.containerPallets.map(x => x.productId).filter(Boolean))]
                for (let i = 0; i < uniqueProducts.length; i++) {
                    let product = this.shipmentProducts.find(x => x.productId == uniqueProducts[i])
                    let obj = {
                        productId: uniqueProducts[i],
                        product: product.product,
                        productTree: product.productTree,
                        noPallets: [...new Set(this.item.containerPallets.filter(x => x.productId == uniqueProducts[i]).map(x => x.sscc))].length,
                        noCartons: this.item.containerPallets.filter(x => x.productId == uniqueProducts[i]).map(x => (parseInt(x.noCartons))).reduce((a, b) => a + b, 0)
                    }
                    result.push(obj)
                }
                result.sort((a, b) => (a.product.name > b.product.name) ? 1 : ((b.product.name > a.product.name) ? -1 : 0))
            }
            return result
        },
        pallets() {
            let pallets = [].concat.apply(
                [],
                this.bookings.map((x) => x.containerPallets)
            );
            return pallets;
        },
        shipmentCommodities() {
            let result = []
            if (this.shipmentProducts) {
                let colors = ['orange', 'indigo', 'teal', 'red', 'green', 'blue', 'purple', 'pink', 'cyan', 'lime', 'amber', 'brown', 'deep-purple', 'deep-orange', 'light-blue', 'light-green', 'yellow', 'grey', 'blue-grey']
                let uniqueProducts = [...new Set(this.pallets.map(x => x.productId).filter(Boolean))]
                let total = this.pallets.map(x => parseInt(x.noCartons)).reduce((a, b) => a + b, 0)
                for (let i = 0; i < uniqueProducts.length; i++) {
                    let product = this.shipmentProducts.find(x => x.productId == uniqueProducts[i])
                    console.log(product)
                    let cartons = this.pallets.filter(x => x.productId == uniqueProducts[i]).map(x => parseInt(x.noCartons)).reduce((a, b) => a + b, 0)
                    let obj = {
                        productId: uniqueProducts[i],
                        product: product.product,
                        productTree: product.productTree,
                        name: product.product.name,
                        value: parseInt((cartons / total * 100).toFixed(0)),
                        color: colors[i]
                    }
                    result.push(obj)
                }
            }
            result = {
                data: result.map(x => x.value),
                labels: result.map(x => x.name),
                type: 'bar',
                color: '#3F51B5',
                symbol: 'circle',
                smooth: true,
                showInLegend: true,
                name: 'Commodities'
            }
            return result
        },
        shipmentProductSummary() {
            let result = []
            let uniqueProducts = [...new Set(this.pallets.map(x => x.productId).filter(Boolean))]
            let total = this.pallets.map(x => parseInt(x.noCartons)).reduce((a, b) => a + b, 0)
            for (let i = 0; i < uniqueProducts.length; i++) {
                let product = this.shipmentProducts.find(x => x.productId == uniqueProducts[i])
                let obj = {
                    productId: uniqueProducts[i],
                    product: product.product,
                    productTree: product.productTree,
                    class: [],
                    count: [],
                    classCount: []
                }
                let uniqueClass = [...new Set(this.pallets.filter(x => x.productId == uniqueProducts[i]).map(x => x.gradeClassCode).filter(Boolean))]
                for (let j = 0; j < uniqueClass.length; j++) {
                    let sum = this.pallets.filter(x => x.productId == uniqueProducts[i] && x.gradeClassCode == uniqueClass[j]).map(x => parseInt(x.noCartons)).reduce((a, b) => a + b, 0)
                    obj.class.push({
                        class: uniqueClass[j],
                        nettWeight: this.pallets.filter(x => x.productId == uniqueProducts[i] && x.gradeClassCode == uniqueClass[j]).map(x => x.nettWeight).reduce((a, b) => a + b, 0),
                        grossWeight: this.pallets.filter(x => x.productId == uniqueProducts[i] && x.gradeClassCode == uniqueClass[j]).map(x => x.grossWeight).reduce((a, b) => a + b, 0),
                        sum: sum,
                        percentage: ((sum / total) * 100).toFixed(0)
                    })
                    // let uniqueCounts = [...new Set(this.item.containerPallets.filter(x => x.productId == uniqueProducts[i] && x.gradeClassCode == uniqueClass[j]).map(x => x.countSize).filter(Boolean))]
                }
                result.push(obj)
            }
            return result
        },
        temperatureDevices() {
            let result = []
            if (this.item && this.item.containerPallets) {
                result = [...new Set(this.item.containerPallets.map((item) => item.sensorSerial).filter(Boolean))]
                result = result.map(x => ({
                    sensor: x,
                    sscc: this.item.containerPallets.find(y => y.tempSerialNo == x).sscc
                }))
            }
            return result
        },
        uniquePallets() {
            let result = []
            if (this.item && this.item.containerPallets) {
                result = [...new Set(this.item.containerPallets.map((item) => item.sscc))]
            }
            return result
        }
    },
    methods: {
        calculateProductCartons(productId) {
            let pallets = this.item.containerPallets.filter((x) => x.productId == productId && x.packType == "CARTON");
            return pallets.reduce((a, b) => a + parseInt(b.noCartons), 0);
        },
        calculateProductBins(productId) {
            let bins = this.item.containerPallets.filter((x) => x.productId == productId && x.packType == "BIN");
            return bins.reduce((a, b) => a + parseInt(b.noCartons), 0);
        },
        calculateUniquePallets(pallets) {
            return [...new Set(pallets.map((item) => item.sscc))].length;
        },
        calculateCartons(pallets) {
            let result = pallets.map((item) => parseInt(item.noCartons));
            return result && result.length > 0 ? result.reduce((a, b) => a + b) : 0;
        },
        calculateActualGrossWeight(pallets) {
            return pallets.map((item) => item.grossWeight).reduce((a, b) => a + b);
        },
        calculateActualNettWeight(pallets) {
            return pallets.map((item) => item.nettWeight).reduce((a, b) => a + b);
        },
        containerGrossWeight(container) {
            return container.containerPallets.map((item) => (Math.round(parseFloat(item.grossWeight) * 100) / 100)).reduce((a, b) => a + b, 0).toFixed(2);
        },
        containerNettWeight(container) {
            return container.containerPallets.map((item) => (Math.round(parseFloat(item.nettWeight) * 100) / 100)).reduce((a, b) => a + b, 0).toFixed(2);
        },
        containerProductSummary(container) {
            let uniqueProducts = [...new Set(container.containerPallets.map(x => x.productId).filter(Boolean))]
            let result = []
            let total = container.containerPallets.map(x => parseInt(x.noCartons)).reduce((a, b) => a + b, 0)
            for (let i = 0; i < uniqueProducts.length; i++) {
                let filteredPallets = container.containerPallets.filter(x => x.productId && x.productId == uniqueProducts[i])
                let sumCartons = filteredPallets.map(x => parseInt(x.noCartons)).reduce((a, b) => a + b, 0)
                let varieties = [...new Set(filteredPallets.filter(x => x.varietyProductId).map((y => y.varietyProductId)))]
                let product = this.shipmentProducts.find(x => x.productId == uniqueProducts[i])
                let findVariety = this.shipmentProducts.filter(x => x.productId == uniqueProducts[i] && varieties.includes(x.varietyProductId))
                for (let j = 0; j < findVariety.length; j++) {
                    let filteredVariety = filteredPallets.filter(x => x.varietyProductId && x.varietyProductId == findVariety[j].varietyProductId)
                    let sumVariety = filteredVariety.map(x => parseInt(x.noCartons)).reduce((a, b) => a + b, 0)
                    let obj = {
                        productId: uniqueProducts[i],
                        product: product.product,
                        productPercentage: ((sumCartons / total) * 100).toFixed(0),
                        variety: findVariety[j].varietyProduct,
                        varietyPercentage: ((sumVariety / sumCartons) * 100).toFixed(0),
                    }
                    result.push(obj)
                }
            }
            return result

        },
        formatDate(date) {
            let result = null;
            if (date) {
                result = dateFormat(new Date(date), "dd-mm-yyyy HH:MM");
            }
            return result;
        },
        formatEventDate(date) {
            let year = date.substring(0, 4);
            let month = parseInt(date.substring(4, 6)) - 1;
            let day = date.substring(6, 8);
            let hour = date.substring(8, 10);
            let minute = date.substring(10, 12);
            let setDate = new Date(year, month, day, hour, minute);
            return dateFormat(setDate, "dd mmm, yyyy HH:MM");
        },
        formatDateTime(date) {
            let result = null;
            if (date) {
                result = dateFormat(new Date(date), "dd-mm-yyyy HH:MM");
            }
            return result;
        },
        getPackType() {
            let type = this.item.containerPallets.find(x => x.packType)
            return type ? type.packType : 'CARTONS'
        },
        async getSensorDetails() {
            if (this.temperatureDevices && this.temperatureDevices.length > 0) {
                this.loadingSensor = true
                for (let i = 0; i < this.temperatureDevices.length; i++) {
                    let data = await this.$API.getSensorDetailsBySerial(this.temperatureDevices[i].sensor)
                    if (data && data.locationData && data.locationData.length > 0) {
                        data.lastLocation = {
                            lat: data.locationData.at(-1).latitude,
                            lng: data.locationData.at(-1).longitude
                        }
                        data.lastLocationDate = data.locationData.at(-1).readingDate
                    }
                    if (data && data.temperatureData && data.temperatureData.length > 0) {
                        data.lastTemperature = data.temperatureData.at(-1).temperature
                        data.lastTemperatureDate = data.temperatureData.at(-1).readingDate
                    }
                    if (data && data.lightData && data.lightData.length > 0) {
                        data.lastLightData = data.lightData.at(-1).light
                        data.lastLightData = data.lightData.at(-1).readingDate
                    }
                    if (data && data.id) {
                        this.sensorData.push(data)
                    }
                }
                this.loadingSensor = false
            } else if (this.item.tempSerialNo) {
                this.loadingSensor = true
                let data = await this.$API.getSensorDetailsBySerial(this.item.tempSerialNo)
                if (data && data.locationData && data.locationData.length > 0) {
                    data.lastLocation = {
                        lat: data.locationData.at(-1).latitude,
                        lng: data.locationData.at(-1).longitude
                    }
                    data.lastLocationDate = data.locationData.at(-1).readingDate
                }
                if (data && data.temperatureData && data.temperatureData.length > 0) {
                    data.lastTemperature = data.temperatureData.at(-1).temperature
                    data.lastTemperatureDate = data.temperatureData.at(-1).readingDate
                }
                if (data && data.lightData && data.lightData.length > 0) {
                    data.lastLight = data.lightData.at(-1).light
                    data.lastLightDate = data.lightData.at(-1).readingDate
                }
                if (data && data.id) {
                    this.sensorData.push(data)
                }
                this.loadingSensor = false
            }
        },
        async trackContainer(container) {
            this.container = container;
            let data = await axios.get(container.eventUrl)
            this.eventType = container.eventUrl.includes('OceanInsight') ? 'OceanInsight' : 'DCSA'
            this.events = data.data
            this.trackerModal = true;
        },
        async getContainerTelemetry(item) {
            item.telemetry = []
            let result = await this.$API.getContainerTelemetryData(item.id)
            item.telemetry = result
            this.telemetryKey++
        },

        async viewContainer(item) {
            this.containerTab = 'summary'
            this.item = item
            this.eventType = null
            if (item.eventUrl) {
                this.eventType = item.eventUrl.includes('OceanInsight') ? 'OceanInsight' : 'DCSA'
                try {
                    let data = await axios.get(item.eventUrl)
                    this.events = data.data
                } catch (e) {
                    this.$message.error('Error getting events')
                }

            } else {
                this.events = []
            }
            this.sensorData = []
            this.getSensorDetails()
            this.getContainerTelemetry(this.item)
            this.containerModal = true
            this.loadingBreakdown = true
            this.summaryItems = await this.$API.getContainerSummary(this.shipment.id, this.item.id)
            this.loadingBreakdown = false
        },
        viewTelemetryShipment(item) {
            this.$router.push({
                path: "/telemPlus/shipment/" + item.id,
            });
        },
    }
}
</script>